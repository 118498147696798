import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CreateEmployee } from "../../_services/affiliate.services";
import { toast } from "react-toastify";
import InnerPageHeader from "components/common/InnerPageHeader";
import { AddBonus } from "_services";

export default function Add(props) {
  const navigate = useNavigate();

  const [bonus, setBonus] = useState("");
  const [minAmount, setMinAmount] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      bonus === "" ||
      minAmount === ""

    ) {
      toast.error(
        <div>
          <i
            className="fa fa-times"
            style={{ fontWeight: "bold", marginRight: "1rem" }}
            aria-hidden="true"
          ></i>
          <span>All filds required</span>
        </div>,
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
      // toast.error("All filds required");
    } else {
      let sendObj = {};
      sendObj.bonus = bonus;
      sendObj.min_amount = minAmount;

      AddBonus(sendObj)
        .then(() => {
          navigate("/bonus-management");
        })
        .catch(() => {
          toast.error(
            <div>
              <i
                className="fa fa-times"
                style={{ fontWeight: "bold", marginRight: "1rem" }}
                aria-hidden="true"
              ></i>
              <span>The username field must contain a unique value</span>
            </div>,
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
          // toast.error(" The username field must contain a unique value");
        });
    }
  };

  return (
    <>
      <InnerPageHeader
        pageTitle="Add Bonus"
        breadcrumbs={[
          { title: "Dashboard", url: "/" },
          { title: "Bonus List", url: "/affiliate" },
        ]}
        currenPageTitle="Add Bonus"
      />
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-content">
                <div className="row">
                  <div className="col-lg-12">
                    <form autoComplete="off">
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          Bonus
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            name="bonus"
                            className="form-control"
                            placeholder="Enter Bonus"
                            value={bonus}
                            autoComplete="off"
                            onChange={(e) => setBonus(e.target.value)}
                          />
                        </div>
                      </div>
                      <hr />

                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          Min Amount
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            name="minAmount"
                            className="form-control"
                            placeholder="Enter Minimum Amount"
                            value={minAmount}
                            autoComplete="off"
                            onChange={(e) => setMinAmount(e.target.value)}
                          />
                        </div>
                      </div>
                      <hr />

                    </form>
                    <div className="form-group row">
                      <div className="col-sm-4 col-sm-offset-2">
                        <Link
                          className="btn btn-white mr-3"
                          type="button"
                          style={{ marginRight: "1rem" }}
                          to="/bonus-management"
                        >
                          Cancel
                        </Link>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={(e) => onSubmit(e)}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
