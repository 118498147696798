/* eslint-disable */
import { actionDeposit, getUserLocation } from "_services";
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
const DepositRequestModal = (props) => {
  const {
    isShow,
    modalToggle,
    getDepositList,
    data
  } = props;

  const [amount, setAmount] = useState(data.amount);
  const [remark, setRemark] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [location, setLocation] = useState(false);


  useEffect(() => {
    getUserLocationDetails()
  }, [])

  const getUserLocationDetails = () => {
    getUserLocation().then((response) => {
      if (response) {
        setLocation(response);
      }
    })
  }

  const onSubmit = (amount, type) => {
    setLoading(true)
    let param = {
      amount,
      remark,
      reference_no: data.reference_no,
      status: type
    }

    if (location != "") {
      param['lat'] = location.lat
      param['lon'] = location.lon
    }


    actionDeposit(param)
      .then((response) => {
        if (response) {
          setLoading(false)
          modalToggle()
          getDepositList()
        }
      })
      .catch((error) => {
        if (error.response.data.error != "") {
          toast.error(error.response.data.error)
        }
        else {
          toast.error("some thing went wrong")
        }
        setLoading(false)
      });
  }

  return (
    <div>
      {isShow && (
        <Modal
          centered
          show={isShow}
          onHide={() => modalToggle()}
          style={{ opacity: 1 }}
          backdrop={false}
        >
          <Modal.Header >Deposit Request</Modal.Header>
          <Modal.Body>
            <div className=" gap-2">

              <div style={{ width: "100%" }}>
                <label>Amount</label>
                <input
                  className="form-control input-sm"
                  // aria-controls="DataTables_Table_0"
                  name="amount"
                  type="number"
                  value={amount}
                  placeholder={"Enter Amount"}
                  onChange={(e) => setAmount(e.target.value)
                  }
                  disabled
                />
              </div>
              <div style={{ width: "100%" }}>
                <label>Remark</label>
                <textarea
                  className="form-control input-sm"
                  // aria-controls="DataTables_Table_0"
                  name="remark"
                  type="text"
                  value={remark}
                  placeholder={"Comment / Remark"}
                  onChange={(e) => setRemark(e.target.value)
                  }
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={(!(amount > 0) || isLoading)} onClick={() => onSubmit(amount, 1)}>
              Approve
            </Button>
            <Button disabled={(!(amount > 0) || isLoading)} onClick={() => onSubmit(amount, 2)}>
              Reject
            </Button>
            <Button disabled={isLoading} variant="outline-danger" onClick={() => modalToggle()}>
              cancel
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default DepositRequestModal