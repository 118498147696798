import { userProfileConstants } from "../../_constants";
import { getAffiliateDashboard, getClientDashboard, getDpWdDashboard, profileDetails } from "../../_services";

export const userProfileRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: userProfileConstants.USERPROFILE_REQUEST, data: {} });
    profileDetails(data)
      .then((response) => {
        dispatch({
          type: userProfileConstants.USERPROFILE_SUCCESS,
          payload: response.data,
        });
        if (callback) callback(response.data);
      })
      .catch((error) => {
        dispatch({
          type: userProfileConstants.USERPROFILE_FAILED,
          payload: error.response,
        });
        if (callback) callback(false);
      });
  };
};

export const getAffiliateDashboardRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: userProfileConstants.AFFILIATE_DASHBOARD_REQUEST, data: {} });
    getAffiliateDashboard(data)
      .then((response) => {
        dispatch({
          type: userProfileConstants.AFFILIATE_DASHBOARD_SUCCESS,
          payload: response.data,
        });
        if (callback) callback(response.data);
      })
      .catch((error) => {
        dispatch({
          type: userProfileConstants.AFFILIATE_DASHBOARD_FAILED,
          payload: error.response,
        });
        if (callback) callback(false);
      });
  };
};

export const getClientDashboardRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: userProfileConstants.CLIENT_DASHBOARD_REQUEST, data: {} });
    getClientDashboard(data)
      .then((response) => {
        dispatch({
          type: userProfileConstants.CLIENT_DASHBOARD_SUCCESS,
          payload: response.data,
        });
        if (callback) callback(response.data);
      })
      .catch((error) => {
        dispatch({
          type: userProfileConstants.CLIENT_DASHBOARD_FAILED,
          payload: error.response,
        });
        if (callback) callback(false);
      });
  };
};
export const getDpDwDashboardRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: userProfileConstants.DP_WD_DASHBOARD_REQUEST, data: {} });
    getDpWdDashboard(data)
      .then((response) => {
        dispatch({
          type: userProfileConstants.DP_WD_DASHBOARD_SUCCESS,
          payload: response.data,
        });
        if (callback) callback(response.data);
      })
      .catch((error) => {
        dispatch({
          type: userProfileConstants.DP_WD_DASHBOARD_FAILED,
          payload: error.response,
        });
        if (callback) callback(false);
      });
  };
};
