import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";


import locked from "../../images/locked.png";
import unlocked from "../../images/unlocked.png";

import ReactPaginate from "react-paginate";
import InnerPageHeader from "components/common/InnerPageHeader";
import { ActiveInactiveBonusOffers, DeleteBonus, getBonusList } from "_services";

export default function List() {
  const [bonusList, setBonusList] = useState([]);

  useEffect(() => {
    getBonusListRequest();
  }, []);

  const getBonusListRequest = () => {
    getBonusList()
      .then((response) => {
        if (response?.data?.data) {
          setBonusList(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const statusChange = (bonus_id, status) => {
    let sendObj = {};
    sendObj.bonus_id = bonus_id;
    sendObj.status = status == "0" ? 1 : 0;

    ActiveInactiveBonusOffers(sendObj)
      .then((response) => {
        if (response) {
          getBonusListRequest()
            .then((response) => {
              if (response?.data?.data) {
                setBonusList(response?.data?.data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };


  const deleteFunction = (bonus_id) => {
    let sendObj = { bonus_id };
    let result = window.confirm('Are you sure you want to delete this offer ?')
    if (result) {
      DeleteBonus(sendObj)
        .then((response) => {
          if (response) {
            getBonusList()
              .then((response) => {
                if (response?.data?.data) {
                  setBonusList(response?.data?.data);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <InnerPageHeader
        pageTitle="BONUS LIST"
        breadcrumbs={[{ title: "Dashboard", url: "/" }]}
        currenPageTitle="BONUS LIST"
      />
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>Bonus List</h5>
                <div className="ibox-tools">
                  <Link to="/add_bonus" className="btn btn-primary btn-xs">
                    Add Bonus Offer
                  </Link>
                </div>
              </div>
              <div className="ibox-content">
                <div className="table-responsive">
                  <table className="table table-bordered table-responsive  table-striped dataTables-example">
                    <thead>
                      <tr>
                        <th style={{ maxWidth: '25px' }}>Sr No</th>
                        <th>Bonus ID</th>
                        <th>Bonus</th>
                        <th>Min Amount</th>
                        <th className="text-center"> Active / Inactive </th>
                        <th className="text-center" style={{ width: "14%" }}>
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {bonusList?.map((element, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{element.bonus_id}</td>
                            <td>{element.bonus}</td>
                            <td>{element.min_amount}</td>

                            <td className="text-center">
                              <a
                                onClick={(e) =>
                                  statusChange(
                                    element.bonus_id,
                                    element.status
                                  )
                                }
                              >
                                <img
                                  src={element.status == 1 ? unlocked : locked}
                                />
                              </a>
                            </td>

                            <td>
                              <a
                                className="btn btn-white btn-sm"
                                onClick={(e) =>
                                  deleteFunction(element.bonus_id)
                                }
                              >
                                <i className="fa fa-trash"></i> Delete
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <div className="paginate_class">
                    <ReactPaginate
                      previousLabel={"prev"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      // pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      // onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
