import config from "../lib/app-config";
import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";
import { toast } from "react-toastify";

export const create = async (data) => {
  try {
    const res = await performRequest(
      apiEndPoints.methodType.POST,
      `${config.api_baseurl}users/create`,
      data
    );
    return res?.data;
  } catch (error) {
    if (error && error.message == "Network Error") {
      toast.error("Please check your network connection.");
    }
    throw error;
  }
};

export const list = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/downline`,
    data
  );
};

export const lockedUserList = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/locked_users_list`,
    data
  );
};

export const betLockUnLock = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/lock_unlock_bet`,
    data
  );
};

export const casinolockUnlock = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/lock_unlock_casino_bet`,
    data
  );
};

export const activeInactiveUsers = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/active_inactive`,
    data
  );
};

export const matkalockUnlock = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/lock_unlock_matka`,
    data
  );
};
