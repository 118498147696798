import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  ActiveInactiveEmployee,
  getEmployeeList,
  // activeDeactiveStaff,
  // deleteStaff,
} from "../../_services/affiliate.services";

import locked from "../../images/locked.png";
import unlocked from "../../images/unlocked.png";

import ReactPaginate from "react-paginate";
import InnerPageHeader from "components/common/InnerPageHeader";
import { UpdateBalanceModal } from "modals";
import { toast } from "react-toastify";
import { depositBalance, withdrawBalance } from "_services/common.services";

export default function List() {
  const [affiliateList, setAffiliateList] = useState([]);
  const [clipboard, setClipboard] = useState(false);
  const [eventLoading, setEventLoading] = useState(false);
  const [UpdateBalanceModalShow, setUpdateBalanceModalShow] = useState(false);
  const [UpdateBalanceModalTitle, setUpdateBalanceModalTitle] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    getAffiliateList();
  }, []);

  const getAffiliateList = () => {
    getEmployeeList()
      .then((response) => {
        if (response?.data?.data) {
          setAffiliateList(response?.data?.data?.list);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const statusChange = (id, status) => {
    let sendObj = {};
    sendObj.user_guid = id;
    sendObj.status = status == "0" ? 1 : 0;

    ActiveInactiveEmployee(sendObj)
      .then((response) => {
        if (response) {
          getEmployeeList()
            .then((response) => {
              if (response?.data?.data) {
                setAffiliateList(response?.data?.data.list);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const deleteFunction = (id) => {
    let sendObj = {};
    sendObj.user_guid = id;

    // deleteStaff(sendObj)
    //   .then((response) => {
    //     if (response) {
    //       staffUserList()
    //         .then((response) => {
    //           if (response?.data?.data) {
    //             setAffiliateList(response?.data?.data);
    //           }
    //         })
    //         .catch((error) => {
    //           console.log(error);
    //         });
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  const setToggleBalanceModal = (item, type) => {
    setSelectedUser(item)
    setUpdateBalanceModalTitle(type)
    setUpdateBalanceModalShow(true)
  }


  const modalHide = () => {
    setUpdateBalanceModalShow(false)
  }

  const updateUserBalance = async (amount) => {
    const profileData = JSON.parse(localStorage.getItem("userLogin"))
    const paload = {
      user_guid: selectedUser?.user_guid,
      amount: amount,
    };
    setEventLoading(true);
    if (UpdateBalanceModalTitle === "Withdraw") {
      try {
        const res = await withdrawBalance(paload);
        handleResponse(res, profileData.balance + amount);
      } catch (error) {
        setEventLoading(false);
        modalHide()
      }
    }

    if (UpdateBalanceModalTitle === "Deposit") {
      try {
        const res = await depositBalance(paload);
        handleResponse(res, profileData.balance - amount);
      } catch (error) {
        setEventLoading(false);
        modalHide()
      }
    }
  };

  const handleResponse = (res, amount) => {

    if (res?.data?.message) {
      toast.success(res?.data?.message);
      let currentUser = JSON.parse(localStorage.getItem("userLogin"))
      currentUser.balance = amount
      localStorage.setItem("userLogin", JSON.stringify(currentUser));
      setAffiliateList();
    }
    if (res?.data?.error) toast.error(res?.data?.error);
    this.modalHide();
    setEventLoading(false);
  };


  const UpdateBalanceModalProps = {
    title: UpdateBalanceModalTitle,
    isShow: selectedUser,
    parentBalance: localStorage.getItem("userLogin") ? +JSON.parse(localStorage.getItem("userLogin")).balance : 0,
    modalToggle: modalHide,
    onSubmit: (amount) => updateUserBalance(amount),
    loading: eventLoading,
    cancleText: "Cancel",
  }

  return (
    <>
      <InnerPageHeader
        pageTitle="AFFILIATE LIST"
        breadcrumbs={[{ title: "Dashboard", url: "/" }]}
        currenPageTitle="AFFILIATE LIST"
      />
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>Affiliate List</h5>
                <div className="ibox-tools">
                  <Link to="/add_affiliate" className="btn btn-primary btn-xs">
                    Create Affiliate
                  </Link>
                </div>
              </div>
              <div className="ibox-content">
                <div className="table-responsive">
                  <table className="table table-bordered table-responsive  table-striped dataTables-example">
                    <thead>
                      <tr>
                        <th>S No.</th>
                        <th>Name</th>
                        <th>User Name</th>
                        <th>Referral Code</th>
                        <th>Bonus %</th>
                        <th>Client Bonus %</th>
                        <th>Total Bonus Earned</th>
                        <th>Current Balance</th>
                        <th>Withdraw</th>
                        <th>Deposit</th>
                        <th>Signup Count</th>
                        <th className="text-center"> Active / Inactive </th>
                        <th className="text-center" style={{ width: "14%" }}>
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {affiliateList?.map((element, ind) => {
                        return (
                          <tr key={ind}>
                            <td>{ind + 1}</td>
                            <td>{element.first_name}{" "}{element.last_name}</td>
                            <td>{element.username}</td>
                            <td style={{ whiteSpace: 'nowrap', display: 'flex', justifyContent: 'space-between', width: '250px', height: '47px', alignItems: 'center' }}>{element.referral_code}
                              <div style={{ background: '#ECCB40', padding: '2px 5px', cursor: 'pointer', borderRadius: '2px' }}
                                onClick={() => { navigator.clipboard.writeText(element.referral_link); setClipboard(element.referral_link) }}>
                                {clipboard && clipboard == element.referral_link ? "Copied" : "Copy Link"}
                              </div>
                            </td>
                            <td>{element.my_share}</td>
                            <td>{element.my_agent_share}</td>
                            <td>{element.total_bonus_earned}</td>

                            <td>{element.balance}</td>
                            <td>
                              <button
                                type="button"
                                className="btn anvGreenBtn"
                                onClick={() =>
                                  setToggleBalanceModal(element, "Withdraw")
                                }
                              >
                                Withdraw
                              </button>
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn anvYellowBtn"
                                onClick={() =>
                                  setToggleBalanceModal(element, "Deposit")
                                }
                              >
                                Deposit
                              </button>
                            </td>
                            <td>{element.total_registered}</td>

                            <td className="text-center">
                              <a
                                onClick={(e) =>
                                  statusChange(
                                    element.user_guid,
                                    element.status
                                  )
                                }
                              >
                                <img
                                  src={element.status == 1 ? unlocked : locked}
                                />
                              </a>
                            </td>

                            <td>
                              {/* <Link
                                className="btn btn-white btn-sm"
                                to="/edit_affiliate"
                                state={{ edit: element }}
                              >
                                <i className="fa fa-pencil-square"></i> Edit
                              </Link>
                              &nbsp;&nbsp;&nbsp;
                              <a
                                className="btn btn-white btn-sm"
                                onClick={(e) =>
                                  deleteFunction(element.user_guid)
                                }
                              >
                                <i className="fa fa-trash"></i> Delete
                              </a> */}


                              <Link
                                className="btn btn-white btn-sm"
                                to={`/edit_password/${element.user_guid}`}
                              >
                                <i className="fa fa-key"></i> CP
                              </Link>

                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <div className="paginate_class">
                    <ReactPaginate
                      previousLabel={"prev"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      // pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      // onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {UpdateBalanceModalShow && <UpdateBalanceModal {...UpdateBalanceModalProps} />}
    </>
  );
}
