import DashboardCard from "components/common/DashboardCard/DashboardCard";
import InnerPageHeader from "../common/InnerPageHeader";
import React, { useState, useEffect, Fragment } from "react";
import "./Dashboard.css";
import { getAffiliateDashboardRequest, getClientDashboardRequest, getDpDwDashboardRequest, userProfileRequest } from "_actions/userprofile/userprofile.actions";
import { useDispatch } from "react-redux";
import { FaChartColumn } from "react-icons/fa6";
import { BiSolidCricketBall } from "react-icons/bi";
import { MdAccessTime } from "react-icons/md";
import { FaRegUserCircle } from "react-icons/fa";
import { IoFootballOutline } from "react-icons/io5";
import { IoTennisballOutline } from "react-icons/io5";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { GrMoney } from "react-icons/gr";
import { FaUserTie } from "react-icons/fa";
import moment from "moment";
import { PiUsersThreeFill } from "react-icons/pi";
import { GiPayMoney } from "react-icons/gi";
import { FaMoneyBillAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BsFileBarGraph } from "react-icons/bs";
import DateRangePickerComponent from "components/common/DateRangePickerComponent";
import { getDepositList } from "_services";

const Dashboard = () => {

  const [activeTab, setTab] = useState(1)
  const [affiliteDashboard, setAffiliteDashboard] = useState([])
  const [clientDashboard, setClientDashboard] = useState([])
  const [dp_dw_Dashboard, setDpDwDashboard] = useState([])

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [firstDepositList, setFirstDepositList] = useState(null);
  const [otherDepositList, setOtherDepositList] = useState(null);

  const dispatch = useDispatch();
  let role = localStorage.getItem("role") || null;
  const [state, setState] = useState({
    userdata: [],
    role: 0,
    roleName: [
      "Company",
      "Company",
      "Sub company",
      "Sub admin",
      "Admin",
      "Super senior",
      "Super",
      "Dealer",
      "Agent",
      "Client",
      "Staff",
      "Affiliate"
    ],
  });

  useEffect(() => {
    const fetchAllDepositLists = async () => {
      try {
        const [firstListData, otherListData] = await Promise.all([
          fetchDepositList(1),
          fetchDepositList(0)
        ]);

        setFirstDepositList(firstListData);
        setOtherDepositList(otherListData);
      } catch (error) {
        console.error("Failed to fetch deposit lists:", error);
      }
    };

    fetchAllDepositLists();
    getClientD()
  }, []);

  const fetchDepositList = async (type) => {
    const param = { type };
    const response = await getDepositList(param);
    return response.data?.data?.records;  // Assuming 'data' is where the list is located
  };



  useEffect(() => {
    setState((s) => ({ ...s, role }));

    let sendObj = {
      role: role,
    };
    dispatch(
      userProfileRequest(sendObj, (res) => {
        if (res && res.data) {
          setState((s) => ({ ...s, userdata: res.data }));
        }
      })
    );

    const from_date = fromDate ? (moment(moment.utc(fromDate).local().format("YYYY-MM-DD 00:00:00"), "YYYY-MM-DD HH:mm:ss").utc().format("YYYY-MM-DD HH:mm:ss")) : "";
    const to_date = toDate ? (moment(moment.utc(toDate).local().format("YYYY-MM-DD 23:59:00"), "YYYY-MM-DD HH:mm:ss").utc().format("YYYY-MM-DD HH:mm:ss")) : '';
    sendObj.from_date = from_date;
    sendObj.to_date = to_date;

    dispatch(
      getAffiliateDashboardRequest(sendObj, (res) => {
        if (res && res.data) {
          setAffiliteDashboard(res.data.records)
        }
      })
    );

    // if (Number(role) == 8) {
    let sendObj_ = {
    };
    // dispatch(
    //   getAffiliateDashboardRequest(sendObj, (res) => {
    //     if (res && res.data) {
    //       setAffiliteDashboard(res.data.records)
    //     }
    //   })
    // );
    dispatch(
      getDpDwDashboardRequest(sendObj_, (res) => {
        if (res && res.data) {
          setDpDwDashboard(res.data.records);
        }
      })
    );
    // }


  }, [role]);

  const getClientD = () => {
    let sendObj = {};
    dispatch(
      getClientDashboardRequest(sendObj, (res) => {
        if (res && res.data) {
          setClientDashboard(res.data.records)
        }
      })
    );
  }

  const handleEvent = (from_date, to_date) => {
    if (from_date) {
      let fromDate = from_date._d;
      let toDate = to_date._d;
      setToDate(toDate);
      setFromDate(fromDate);
    }
  }

  const fetchDashboardDataByDate = () => {
    let sendObj = {
      role: role,
    };

    const from_date = fromDate ? (moment(moment.utc(fromDate).local().format("YYYY-MM-DD 00:00:00"), "YYYY-MM-DD HH:mm:ss").utc().format("YYYY-MM-DD HH:mm:ss")) : "";
    const to_date = toDate ? (moment(moment.utc(toDate).local().format("YYYY-MM-DD 23:59:00"), "YYYY-MM-DD HH:mm:ss").utc().format("YYYY-MM-DD HH:mm:ss")) : '';
    sendObj.from_date = from_date;
    sendObj.to_date = to_date;

    dispatch(
      getAffiliateDashboardRequest(sendObj, (res) => {
        if (res && res.data) {
          setAffiliteDashboard(res.data.records)
        }
      })
    );
  }


  const { userdata } = state;
  return (
    <Fragment>
      <Fragment>
        {/* {userdata.role && Number(userdata.role) != 8 ? <Fragment>
        <InnerPageHeader
          pageTitle="Live Report Matches"
          breadcrumbs={[{ title: "Dashboard", url: "" }]}
          currenPageTitle={
            userdata?.username
              ? `${userdata.username} (${userdata.first_name})`
              : ""
          }
        />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="DashBoardCardContainer">
            <DashboardCard
              title={"MY USERNAME"}
              text={userdata.username}
              lastName={"" + userdata.name + ""}
            >
              <div className="card_style" style={{ backgroundColor: '#7C3AED' }}>
                <FaChartColumn size={'20'} color="white" />
              </div>

            </DashboardCard>

            <DashboardCard
              title={"MY LEVEL"}
              text={state?.roleName[state?.role]}
            >
              <div className="card_style" style={{ backgroundColor: '#FB923C' }}>
                <FaUserTie size={'20'} color="white" />
              </div>

            </DashboardCard>


            <DashboardCard
              title={"CURRENT BALANCE"}
              text={userdata.balance ? userdata.balance : ""}
            >
              <div className="card_style" style={{ backgroundColor: '#60A5FA' }}>
                <FaMoneyBillTrendUp size={'20'} color="white" />
              </div>

            </DashboardCard>

            <DashboardCard
              title={"Company Contact"}
              text={state?.roleName[userdata?.parent_role]}
            >
              <div className="card_style" style={{ backgroundColor: '#F87171' }}>
                <FaRegUserCircle size={'20'} color="white" />
              </div>
            </DashboardCard>
            {(userdata.role == 11 || userdata.role == 8) && (
              <>
                <DashboardCard
                  title={"My Bonus %"}
                  text={userdata.my_share + "%"}
                >
                  <div className="card_style" style={{ backgroundColor: '#2196f3' }}>
                    <FaChartColumn size={'20'} color="white" />
                  </div>
                </DashboardCard>

                <DashboardCard
                  title={"Client Bonus %"}
                  text={userdata.my_agent_share + "%"
                  }
                >
                  <div className="card_style" style={{ backgroundColor: '#ff5722' }}>
                    <FaChartColumn size={'20'} color="white" />
                  </div>
                </DashboardCard>

                <DashboardCard
                  title={"Total Bonus Earned"}
                  text={userdata.total_bonus_earned + ""
                  }
                >
                  <div className="card_style" style={{ backgroundColor: '#ff9800' }}>
                    <FaChartColumn size={'20'} color="white" />
                  </div>
                </DashboardCard>
                <DashboardCard
                  title={"Refreal code"}
                  text={userdata.referral_code + ""

                  }
                  referral_link={userdata.referral_link}
                >
                  <div className="card_style" style={{ backgroundColor: '#ff9800' }}>
                    <FaChartColumn size={'20'} color="white" />
                  </div>
                </DashboardCard>
                <DashboardCard
                  title={"Signup Counts"}
                  text={userdata.total_registered + ""
                  }

                >
                  <div className="card_style" style={{ backgroundColor: '#ff9800' }}>
                    <FaChartColumn size={'20'} color="white" />
                  </div>
                </DashboardCard>
              </>
            )}

            {(userdata.role != 11 && userdata.role != 8) && (
              <>
                {userdata.role == 2 ? (
                  <DashboardCard
                    title={"Maximum My Share"}
                    text={100 - userdata.company_share + "%"}
                  >
                    <div className="card_style" style={{ backgroundColor: '#7C3AED' }}>
                      <FaChartColumn size={'20'} color="white" />
                    </div>
                  </DashboardCard>
                ) : (
                  <DashboardCard
                    title={"Maximum My Share"}
                    text={
                      userdata.my_agent_share ? userdata.my_agent_share + "%" : ""
                    }
                  >
                    <div className="card_style" style={{ backgroundColor: '#7C3AED' }}>
                      <FaChartColumn size={'20'} color="white" />
                    </div>
                  </DashboardCard>
                )}

                {userdata.role == 2 ? (
                  <DashboardCard
                    title={"Minimum Company Share"}
                    text={userdata.company_share + "%"}
                  >
                    <div className="card_style" style={{ backgroundColor: '#FB923C' }}>
                      <GrMoney size={'20'} color="white" />
                    </div>
                  </DashboardCard>
                ) : (
                  <DashboardCard
                    title={"Minimum Company Share"}
                    text={
                      userdata.my_share ? 100 - userdata.my_agent_share + "%" : ""
                    }
                  >
                    <div className="card_style" style={{ backgroundColor: '#FB923C' }}>
                      <FaChartColumn size={'20'} color="white" />
                    </div>
                  </DashboardCard>
                )}

                <DashboardCard
                  title={"Cricket Commission"}
                  text={
                    userdata.cricket_commission
                      ? (Math.round(userdata.cricket_commission * 100) / 100).toFixed(1)
                      : ""
                  }
                >
                  <div className="card_style" style={{ backgroundColor: '#D13F3D' }}>
                    <BiSolidCricketBall size={'20'} color="white" />
                  </div>
                </DashboardCard>

                <DashboardCard
                  title={"Football Commission"}
                  text={
                    userdata.football_commission
                      ? (Math.round(userdata.football_commission * 100) / 100).toFixed(1)
                      : ""
                  }
                >
                  <div className="card_style" style={{ backgroundColor: '#60A5FA' }}>
                    <IoFootballOutline size={'20'} color="white" />
                  </div>
                </DashboardCard>


                <DashboardCard
                  title={"Tennis Commission"}
                  text={
                    userdata.tennis_commission
                      ? (Math.round(userdata.tennis_commission * 100) / 100).toFixed(1)
                      : ""
                  }
                >
                  <div className="card_style" style={{ backgroundColor: '#E2E438' }}>
                    <IoTennisballOutline size={'20'} color="white" />
                  </div>
                </DashboardCard>

                <DashboardCard
                  title={"Session Commission"}
                  text={
                    userdata.session_commission
                      ? (Math.round(userdata.session_commission * 100) / 100).toFixed(1)
                      : ""
                  }
                >
                  <div className="card_style" style={{ backgroundColor: '#F87171' }}>
                    <GrMoney size={'20'} color="white" />
                  </div>

                </DashboardCard>
              </>)}
          </div>
        </div> 
      </Fragment>*/}
      </Fragment>
      {userdata.role && Number(userdata.role) != 8 ?
        <div className="dashboard-conainer">
          <div className="overview-conainer">
            <div>
              <div className="date-container">
                <div>
                  <p>Today</p>
                  <small>{moment(new Date()).format("MMM DD, YYYY h:mm A")}</small>
                </div>
                <div className="row">
                  <DateRangePickerComponent handleEvent={(from_date, to_date) => handleEvent(from_date, to_date)} fDate={fromDate} tDate={toDate} />
                  <div
                    className="col-lg-3 col-md-4 col-sm-6 col-xs-12"
                    style={{ margin: "11px 0px" }}
                  >
                    <a className="btn btn-primary btn-xs search_date_btn" onClick={() => { fetchDashboardDataByDate() }}>Search</a>
                  </div>
                </div>
              </div>
              <div className="balance-container">
                <div className="balance-row">
                  <p>Deposit</p>
                  <p>{affiliteDashboard.total_deposit}</p>
                </div>  <div className="balance-row">
                  <p>Withdraw</p>
                  <p>{affiliteDashboard.total_withdrawal}</p>
                </div>
                <div className="balance-row">
                  <p>P/L</p>
                  <p style={{ color: affiliteDashboard.total_earning < 0 ? "red" : "green" }}>{affiliteDashboard.total_earning}</p>
                </div>
                {/* <div className="balance-row">
                  <p>Total Affiliate P/L</p>
                  <p style={{ color: affiliteDashboard.total_aff_earning < 0 ? "red" : "green" }}>{affiliteDashboard.total_aff_earning}</p>
                </div> */}
                <div className="balance-row">
                  <p>Registered Clients</p>
                  <p>{affiliteDashboard.total_registered}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="dw-card-container">
            <div className="dw-card-row">
              <div className="dw-card">
                <div className="dw-card-item">
                  <BsFileBarGraph size={50} />
                  <p>User: {userdata.username}</p>
                </div>
              </div>
              <Link to={"/my-clients"} className="dw-card">
                <div className="dw-card-item">
                  <PiUsersThreeFill size={50} />
                  <p>Users</p>
                </div>
              </Link>
              {userdata.role && Number(userdata.role) != 10 &&
                <Link to={"/agent"} className="dw-card">
                  <div className="dw-card-item">
                    <PiUsersThreeFill size={50} />
                    <p>Affliate Agents</p>
                  </div>
                </Link>
              }
              <Link to={"/deposit_requests"} className="dw-card">
                <div className="dw-card-item">
                  <GiPayMoney size={50} />
                  <p>Deposit</p>
                </div>
              </Link>
              <Link to={'/withdraw_requests'} className="dw-card">
                <div className="dw-card-item">
                  <FaMoneyBillAlt size={50} />
                  <p>Withdraw</p>
                </div>
              </Link>
              {userdata.role && Number(userdata.role) != 10 &&
                <Link to={'/profit-&-loss'} className="dw-card">
                  <div className="dw-card-item">
                    <FaMoneyBillTrendUp size={50} />
                    <p>Profit Loss</p>
                  </div>
                </Link>
              }
            </div>

          </div>
        </div>
        : <Fragment>
          <div className="dashboard-conainer">
            <div className="overview-conainer">
              <div>
                <div className="date-container">
                  <div>
                    <p>Today</p>
                    <small>{moment(new Date()).format("MMM DD, YYYY h:mm A")}</small>
                  </div>
                  <div className="row">
                    <DateRangePickerComponent handleEvent={(from_date, to_date) => handleEvent(from_date, to_date)} fDate={fromDate} tDate={toDate} />
                    <div
                      className="col-lg-3 col-md-4 col-sm-6 col-xs-12"
                      style={{ margin: "11px 0px" }}
                    >
                      <a className="btn btn-primary btn-xs search_date_btn" onClick={() => { fetchDashboardDataByDate() }}>Search</a>
                    </div>
                  </div>
                </div>
                <div className="balance-container">
                  <div className="balance-row">
                    <p>Deposit</p>
                    <p>{affiliteDashboard.total_deposit}</p>
                  </div>  <div className="balance-row">
                    <p>Withdraw</p>
                    <p>{affiliteDashboard.total_withdrawal}</p>
                  </div>  <div className="balance-row">
                    <p>P/L</p>
                    <p style={{ color: affiliteDashboard.total_earning < 0 ? "red" : "green" }}>{affiliteDashboard.total_earning}</p>
                  </div>  <div className="balance-row">
                    <p>Registered Clients</p>
                    <p>{affiliteDashboard.total_registered}</p>
                  </div>
                </div>
              </div>
            </div>



            <div className="dw-card-container">
              <div className="dw-card-row">
                <Link to={"/my-clients"} className="dw-card">
                  <div className="dw-card-item">
                    <PiUsersThreeFill size={50} />
                    <p>Users</p>
                  </div>
                </Link>
                <Link to={"/deposit_requests"} className="dw-card">
                  <div className="dw-card-item">
                    <GiPayMoney size={50} />
                    <p>Deposit</p>
                  </div>
                </Link>
                <Link to={'/withdraw_requests'} className="dw-card">
                  <div className="dw-card-item">
                    <FaMoneyBillAlt size={50} />
                    <p>Withdraw</p>
                  </div>
                </Link>
                <Link to={'/profit-&-loss'} className="dw-card">
                  <div className="dw-card-item">
                    <FaMoneyBillTrendUp size={50} />
                    <p>Profit Loss</p>
                  </div>
                </Link>
              </div>

            </div>


          </div>
        </Fragment>
      }

      <div className="dw-container">
        {/* <div className="dw-tabs">
                <div className={"tb " + (activeTab == 1 ? "active" : "")} onClick={() => setTab(1)}>Deposits & Withdrawals</div>
                <div className={"tb " + (activeTab == 2 ? "active" : "")} onClick={() => { setTab(2); getClientD(); }}>Clients</div>
              </div> */}
        {/* <div className="dw-title">Total</div> */}

        <div className="table-container">
          <table className="custom-table">
            <thead>
              <tr>
                <th colSpan={2}>REGISTERED USERS</th>
              </tr>
              <tr className="headi">
                <th>Period</th>
                <th>Count</th>
              </tr>
            </thead>
            <tbody>
              {clientDashboard && clientDashboard.length > 0 ? (
                clientDashboard.map((row, index) => (
                  <tr key={index}>
                    <td>{row?.text}</td>
                    <td>{row?.count}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={2} className="no-records">
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>


        <div className="table-container">
          <table className="custom-table">
            <thead>
              <tr>
                <th colSpan={4}>FIRST DEPOSIT</th>
              </tr>
              <tr className="headi">
                <th>Period</th>
                <th>Count</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {firstDepositList && firstDepositList.length > 0 ? (
                firstDepositList.map((row, index) => (
                  <tr key={index}>
                    <td>{row?.text}</td>
                    <td>{row?.count}</td>
                    <td>{row?.amount}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3} className="no-records">
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>


        <div className="table-container">
          <table className="custom-table">
            <thead>
              <tr>
                <th colSpan={3}>DEPOSIT</th>
              </tr>
              <tr className="headi">
                <th>Period</th>
                <th>Count</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {otherDepositList && otherDepositList.length > 0 ? (
                otherDepositList.map((row, index) => (
                  <tr key={index}>
                    <td>{row?.text}</td>
                    <td>{row?.count}</td>
                    <td>{row?.amount}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3} className="no-records">
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="table-container">
          <table className="custom-table">
            <thead>
              <tr>
                <th colSpan={3}>WITHDRAWAL</th>
              </tr>
              <tr className="headi">
                <th>Period</th>
                <th>Count</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {dp_dw_Dashboard && dp_dw_Dashboard.length > 0 ? (
                dp_dw_Dashboard.map((row, index) => (
                  <tr key={index}>
                    <td>{row?.text}</td>
                    <td>{row?.count}</td>
                    <td>{row?.withdrawal}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3} className="no-records">
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>


        <br />



        {/* {activeTab == 1 && <div className="dw-list-container">

                {dp_dw_Dashboard && dp_dw_Dashboard.length > 0 && dp_dw_Dashboard.map((item, index) => (
                  <div className="dw-list" key={index}>
                    <div className="dw-day"><p>{item.text}</p></div>
                    <div className="list-container">
                      <div className="dw-list-content border-dw-list">
                        <div className="dw-list-header">
                          <p>Deposit</p>
                          <p>Withdraw</p>
                        </div>
                        <div className="dw-list-values">
                          <p>{item.deposit}</p>
                          <p>{item.withdrawal}</p>
                        </div>
                      </div>

                      <div className="dw-list-content">
                        <div className="dw-list-header"><p>P/L</p></div>
                        <div className="dw-list-values" style={{ color: item['p/l'] < 0 ? "red" : "green" }}><p>{item['p/l']}</p></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>}
              {activeTab == 2 && <div className="dw-list-container">
                {clientDashboard && clientDashboard.length > 0 && clientDashboard.map((item, index) => (
                  <div className="dw-list" key={index}>
                    <div className="dw-day"><p>{item.text}</p></div>
                    <div className="list-container">
                      <div className="dw-list-content border-dw-list">
                        <div className="dw-list-header">
                          <p>Registered Clients</p>
                        </div>
                        <div className="dw-list-values">
                          <p>{item.count}</p>
                        </div>
                      </div>
                    </div>
                  </div>))}
              </div>} */}

      </div>
    </Fragment>
  );
};
export default Dashboard;
