import React, { Component } from "react";
import InnerPageHeader from "../common/InnerPageHeader";
import { listRequest } from "../../_actions/agent/agent.actions";
import { debounce } from "lodash";

import {
  betLockUnLock,
  casinolockUnlock,
  activeInactiveUsers,
  list,
} from "../../_services/master.services";

import ReactPaginate from "react-paginate";
import { blockedUser } from "_services/settlements.services";

class List extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    agentList: [],
    totalRecords: 0,
    role: 8,
    keyword: "",

    currentPage: 1,
    perPage: 10,
    pageCount: 0,
    clipboard: false
  };

  debouncedLog = debounce((text) => this.fetchAgentData(text), 500);

  componentDidMount() {
    this.fetchAgentData(this.state.keyword);
    this.scrollToTop();
  }

  fetchAgentData = (searchData = "") => {
    let sendObj = {
      user_guid: "",
      current_page: this.state.currentPage,
      items_perpage: this.state.perPage,
      keyword: searchData,
      role: this.state.role,
    };
    if (this.props.isBlocked) {
      this.getBlockedUserList(sendObj);
    } else {
      this.props.dispatch(
        listRequest(sendObj, (res) => {
          if (res && res.data && res.data.total_records) {
            this.setState({
              agentList: res.data.userdownlist,
              totalRecords: res.data.total_records,
            });

            this.setState({
              pageCount: Math.ceil(res.data.total_records / this.state.perPage),
            });
          } else {
            this.setState({
              agentList: [],
              totalRecords: 0,
            });
          }
        })
      );
    }
  };

  getBlockedUserList = (sendData) => {
    blockedUser({ ...sendData, role: this.props.role })
      .then((res) => {
        if (res && res.data && res?.data?.data) {
          this.setState({
            agentList: res?.data?.data?.userdownlist,
            totalRecords: res?.data?.data?.total_records,
          });
          this.setState({
            pageCount: Math.ceil(
              res?.data?.data?.total_records / this.state.perPage
            ),
          });
        } else {
          this.setState({
            agentList: [],
            totalRecords: 0,
          });
        }
      })
      .catch((error) => {
        this.setState({
          agentList: [],
          totalRecords: 0,
        });
      });
  };

  getRecordDetails = () => {
    const { currentPage, totalRecords } = this.state;
    let startingCount =
      this.state.currentPage === 1
        ? 1
        : this.state.currentPage * this.state.perPage - 9;
    let endingCount =
      this.state.perPage < totalRecords
        ? this.state.currentPage === 1
          ? this.state.perPage
          : this.state.currentPage * 10
        : totalRecords;
    return `Showing ${startingCount} to ${endingCount} of ${totalRecords} entries`;
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      this.fetchAgentData(this.state.keyword);
    });
  };

  handleChange = (e) => {
    this.setState({ keyword: e.target.value });
    this.debouncedLog(e.target.value);
  };

  statusChange = (confirmEvent, user, ind) => {
    let sendData = {};
    sendData.user_guid = user.user_guid;
    sendData.status = user.status == "0" ? 1 : 0;

    activeInactiveUsers(sendData)
      .then((response) => {
        // this.setState.masterList[ind].user.status = (user.status == "0" ? 1 : 0);

        if (response) {
          let sendObj = {
            user_guid: user.user_guid,
            current_page: this.state.currentPage,
            items_perpage: this.state.perPage,
            keyword: this.state.keyword,
            role: this.state.role,
          };

          list(sendObj)
            .then((response) => {
              this.setState({ agentList: response.data.data.userdownlist });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  lockUnlockBet = (event, user, ind) => {
    let sendData = {};
    sendData.user_guid = user.user_guid;
    sendData.lock_betting = user.lock_betting == "0" ? 1 : 0;

    betLockUnLock(sendData)
      .then((response) => {
        if (response) {
          let sendObj = {
            user_guid: user.user_guid,
            current_page: this.state.currentPage,
            items_perpage: this.state.perPage,
            keyword: this.state.keyword,
            role: this.state.role,
          };

          list(sendObj)
            .then((response) => {
              this.setState({ agentList: response.data.data.userdownlist });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  lockUnlockCasino(event, user, ind) {
    if (event) {
      let sendData = {};
      sendData.user_guid = user.user_guid;
      sendData.lock_casino_bet = user.lock_casino_bet == "0" ? 1 : 0;
      try {
        casinolockUnlock(sendData)
          .then((response) => {
            if (response) {
              let sendObj = {
                user_guid: user.user_guid,
                current_page: this.state.currentPage,
                items_perpage: this.state.perPage,
                keyword: this.state.keyword,
                role: this.state.role,
              };

              list(sendObj)
                .then((response) => {
                  this.setState({ agentList: response.data.data.userdownlist });
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error, "error");
      }
    }
  }

  scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document,
        behavior: "smooth",
      });
    }, 1000);
    return true;
  };

  render() {
    const { agentList, totalRecords, currentPage, perPage, clipboard } = this.state;
    const { isBlocked, title } = this.props;
    return (
      <>
        <InnerPageHeader
          breadcrumbs={[
            { title: "Dashboard", url: "/" },
            { title: "Agent", url: "/agent" },
          ]}
          pageTitle={title ? title : "Agent List"}
          currenPageTitle="List"
        />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5 style={{ fontWeight: 700 }}>All Agents</h5>
                  {!isBlocked && (
                    <div className="ibox-tools">
                      <a
                        href="/"
                        className="btn btn-primary btn-xs"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.navigate("/agent/add");
                        }}
                      >
                        Create Agent
                      </a>
                    </div>
                  )}
                </div>
                <div className="ibox-content">
                  <div className="table-responsive">
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper form-inline dt-bootstrap"
                    >
                      <div className="dt-buttons">
                        <a
                          className="dt-button buttons-csv buttons-html5"
                          tabIndex="0"
                          aria-controls="DataTables_Table_0"
                          href="#"
                          style={{ marginRight: "0.5rem" }}
                        >
                          <span>CSV</span>
                        </a>
                        <a
                          className="dt-button buttons-pdf buttons-html5"
                          tabIndex="0"
                          aria-controls="DataTables_Table_0"
                          href="#"
                        >
                          <span>PDF</span>
                        </a>
                      </div>

                      <div
                        id="DataTables_Table_0_filter"
                        className="dataTables_filter"
                      >
                        <label>
                          Search:
                          <input
                            type="search"
                            className="form-control input-sm"
                            placeholder=""
                            aria-controls="DataTables_Table_0"
                            value={this.state.keyword}
                            onChange={this.handleChange}
                          />
                        </label>
                      </div>

                      <table className="table table-striped table-bordered table-hover dataTables-example">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>User Name</th>
                            {/* <th>Name</th> */}
                            {/* <th>Fix Limit</th> */}
                            <th>Referral Code</th>
                            <th>My Share</th>
                            <th>Max Share</th>
                            <th>Casino Share</th>
                            <th>My P/L</th>
                            <th>Affiliate P/L</th>
                            <th>Total P/L</th>
                            <th>User Lock</th>
                            <th>Bet Lock</th>
                            <th>Casino Lock</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {agentList && agentList.length ? (
                            agentList.map((user, index) => (
                              <tr className="gradeX" key={index}>
                                <td>
                                  {index + 1 + (currentPage - 1) * perPage}
                                </td>
                                <td>
                                  <a
                                    href="/"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.navigate(
                                        `/userview/agent/${user.user_guid}/${user.username}`
                                      );
                                    }}
                                  >
                                    {user.username} {user.first_name && (`(${user.first_name})`)}
                                  </a>
                                </td>
                                {/* <td>
                                  {user.first_name} {user.last_name}
                                </td> */}
                                {/* <td className="center">{user.fixed_limit}</td> */}
                                <td style={{ whiteSpace: 'nowrap', display: 'flex', justifyContent: 'space-between', width: '250px', height: '47px', alignItems: 'center' }}>{user.referral_code}
                                  <div style={{ background: '#ECCB40', padding: '2px 5px', cursor: 'pointer', borderRadius: '2px' }}
                                    onClick={() => { navigator.clipboard.writeText(user.referral_link); this.setState({ clipboard: user.referral_link }) }}>
                                    {clipboard && clipboard == user.referral_link ? "Copied" : "Copy Link"}
                                  </div>
                                </td>
                                <td className="center">{user.my_share}</td>
                                <td className="center">
                                  {user.my_agent_share}
                                </td>
                                <td className="center">{user.game_share}</td>
                                <td className="center" style={{ color: user.total_pl < 0 ? 'red' : 'green' }}>{user.total_pl}</td>
                                <td className="center" style={{ color: user.affilate_pl < 0 ? 'red' : 'green' }}>{user.affilate_pl}</td>
                                <td className="center" style={{ color: (Number(user.total_pl) + Number(user.affilate_pl)) < 0 ? 'red' : 'green' }}>{Number(user.total_pl) + Number(user.affilate_pl)}</td>
                                <td className="center">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={user.status == "1"}
                                      onChange={(e) =>
                                        this.statusChange(e, user, false)
                                      }
                                    />
                                    <span className="slider round"></span>
                                  </label></td>
                                <td className="center">

                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        user.lock_betting == "0"
                                      }
                                      onChange={(e) =>
                                        this.lockUnlockBet(e, user, false)
                                      }
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </td>
                                <td className="center">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        user.lock_casino_bet == "0"
                                      }
                                      onChange={(e) =>
                                        this.lockUnlockCasino(e, user, false)
                                      }
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </td>
                                <td className="">
                                  <a
                                    className="btn btn-white btn-sm"
                                    href={`/agent/edit/${user?.user_guid}`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.navigate({
                                        pathname: `/agent/edit/${user?.user_guid}`,
                                        state: {
                                          selectedData: user,
                                          lastPath:
                                            this.props.location?.pathname,
                                          isBlocked,
                                        },
                                      });
                                    }}
                                  >
                                    <i className="fa fa-pencil-square"></i> Edit
                                  </a>
                                  &nbsp;&nbsp; &nbsp;&nbsp;
                                  {!isBlocked && (
                                    // <a
                                    //   href="/"
                                    //   onClick={(e) => {
                                    //     e.preventDefault();
                                    //     this.props.navigate(
                                    //       `/edit_password/${user.user_guid}`
                                    //     );
                                    //   }}
                                    // >
                                    //   <button
                                    //     type="button"
                                    //     className="btn btn-w-m btn-primary"
                                    //   >
                                    //     Change Password
                                    //   </button>
                                    // </a>


                                    <a
                                      className="btn btn-white btn-sm"
                                      href="/"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.props.navigate(
                                          `/edit_password/${user.user_guid}`
                                        );
                                      }}
                                    >
                                      <i className="fa fa-key"></i> CP
                                    </a>
                                  )}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="gradeX">
                              <td colSpan={7}>No data available in table</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className="row" style={{ margin: "15px 0px" }}>
                        <div className="col-xs-12 col-sm-6">
                          <div
                            className="dataTables_info"
                            id="DataTables_Table_0_info"
                            role="status"
                            aria-live="polite"
                          >
                            {totalRecords
                              ? this.getRecordDetails()
                              : "Showing 0 to 0 of 0 entries"}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                          <div className="paginate_class">
                            <ReactPaginate
                              previousLabel={"prev"}
                              nextLabel={"next"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={this.state.pageCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={this.handlePageClick}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"}
                            />
                          </div>
                        </div>
                        <div className="clearfix visible-xs"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default List;
