import _ from 'lodash';

const DevHost = ["gamebey-admin.betexchange.guru", "www.gamebey-admin.betexchange.guru",];
const LiveHost = ["localhost", "gamebey.com", "www.gamebey.com", "admin.gamebey.com", "www.admin.gamebey.com"];
const StagHost = ["betexchange.guru", "gamebey-admin.betexchange.guru"];

const hostname = window.location.hostname;

const live = {
  api_baseurl: "https://adminapi.gamebey.com/api/",
  baseurl: "https://adminapi.gamebey.com/",
  socketurl: "https://node.gamebey.com",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
  },
};

const dev = {
  api_baseurl: "https://gamebey-admin-api.betexchange.guru/api/",
  baseurl: "https://gamebey-admin-api.betexchange.guru/",
  socketurl: "https://node.gamebey.com",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
  },
};


const stag = {
  api_baseurl: "https://gamebey-admin-api.betexchange.guru/api/",
  baseurl: "https://gamebey-admin-api.betexchange.guru/",
  socketurl: "https://node-gamebey.betexchange.guru",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
  },
};


const AppConfig = _.includes(LiveHost, hostname) ? live : (_.includes(StagHost, hostname) ? stag : dev);

// const AppConfig =  _.includes(DevHost, hostname)? dev: live;

export default AppConfig;