import React from "react";
import InnerPageHeader from "components/common/InnerPageHeader";
import { useLocation, useParams } from "react-router-dom";

function BetSlips2Component() {
  const location = useLocation();
  const { matchData } = location.state;

  const { event_id } = useParams();
  return (
    <div>
      <InnerPageHeader
        pageTitle="Bet Slips"
        breadcrumbs={[
          { title: "Dashboard", url: "/" },
          { title: "Matches", url: "/matches_list" },
          {
            title: matchData?.event_name,
            url: {
              pathname: `/match_dashboard/${event_id}`,
              state: { matchData },
            },
          },
        ]}
        currenPageTitle="Bet Slips"
      />
      <div className="row">
        <div className="col-lg-3">
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h5>Total Bets</h5>
            </div>
            <div className="ibox-content">
              <h1 className="no-margins">164</h1>
            </div>
          </div>
        </div>

        <div className="col-lg-3">
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h5>Settled Bets</h5>
            </div>
            <div className="ibox-content">
              <h1 className="no-margins">164</h1>
            </div>
          </div>
        </div>

        <div className="col-lg-3">
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h5>Unsettled Bets</h5>
            </div>
            <div className="ibox-content">
              <h1 className="no-margins">0</h1>
            </div>
          </div>
        </div>

        <div className="col-lg-3">
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h5>Reverted Bets</h5>
            </div>
            <div className="ibox-content">
              <h1 className="no-margins">0</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-sm-5 m-b-xs">
            <select
              className="input-sm form-control input-s-sm inline"
              id="user_ids"
              name="user_id"
              style={{ height: "34px" }}
            >
              <option value="">All Users</option>
            </select>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="tabs-container">
            <ul className="nav nav-tabs" role="tablist">
              <li className="active">
                <a
                  className="nav-link"
                  data-toggle="tab"
                  href="#480666"
                  onClick={null}
                >
                  Match Winner
                </a>
              </li>
            </ul>

            <div className="tab-content">
              <div role="tabpanel" id="480666" className="tab-pane active">
                <div className="panel-body">
                  <div className="wrapper wrapper-content animated fadeInRight">
                    <div className="row">
                      <strong>Match Winner</strong>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-3">
                      <div className="ibox float-e-margins">
                        <div className="ibox-title">
                          <h5>Total Bets</h5>
                        </div>
                        <div className="ibox-content">
                          <h1 className="no-margins">164</h1>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3">
                      <div className="ibox float-e-margins">
                        <div className="ibox-title">
                          <h5>Settled Bets</h5>
                        </div>
                        <div className="ibox-content">
                          <h1 className="no-margins">164</h1>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3">
                      <div className="ibox float-e-margins">
                        <div className="ibox-title">
                          <h5>Unsettled Bets</h5>
                        </div>
                        <div className="ibox-content">
                          <h1 className="no-margins">0</h1>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3">
                      <div className="ibox float-e-margins">
                        <div className="ibox-title">
                          <h5>Reverted Bets</h5>
                        </div>
                        <div className="ibox-content">
                          <h1 className="no-margins">0</h1>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="wrapper wrapper-content animated fadeInRight">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                          <div className="ibox-title">
                            <h5>Market Position</h5>
                            <div className="ibox-tools"></div>
                          </div>
                          <div className="ibox-content table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>RUNNER</th>
                                  <th>POSITION</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Essex </td>
                                  <td>33595.25</td>
                                </tr>
                                <tr>
                                  <td> Sussex</td>
                                  <td>-147109.83</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                          <div className="ibox-title">
                            <h5>Bet Slips</h5>
                            <div className="ibox-tools"></div>
                          </div>

                          <div className="ibox-content table-responsive ">
                            <table className="table table-bordered table-striped">
                              <thead>
                                <tr>
                                  <th colSpan="9">Bet</th>
                                  <th colSpan="3">My Share / Position</th>
                                  <th colSpan="2">Settlement</th>
                                </tr>
                              </thead>

                              <thead>
                                <tr>
                                  <th>Bet ID</th>
                                  <th>DATE</th>
                                  <th>RATE</th>
                                  <th>AMOUNT</th>
                                  <th>MODE</th>
                                  <th>Runner Name</th>
                                  <th>User</th>
                                  <th>Essex </th>
                                  <th> Sussex</th>
                                  <th>My Share</th>
                                  <th>Essex </th>
                                  <th> Sussex</th>
                                  <th>Status</th>
                                  <th>Plus / Minus</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td>14612039</td>
                                  <td>
                                    <i className="fa fa-clock-o"></i>&nbsp;Jun
                                    18, 2:33:22 AM
                                  </td>
                                  <td>0.32</td>
                                  <td>1000</td>
                                  <td>LAGAI</td>
                                  <td>Essex</td>
                                  <td>bs55865</td>

                                  <th>320.0</th>
                                  <th>-1000.0</th>

                                  <td>15.0 %</td>
                                  <th className="runner-sum">48.0</th>
                                  <th className="runner-sum">-150.0</th>

                                  <td>
                                    <span className="label label-primary">
                                      settle
                                    </span>
                                  </td>
                                  <td>48.0</td>
                                </tr>

                                <tr>
                                  <td>14609458</td>
                                  <td>
                                    <i className="fa fa-clock-o"></i>&nbsp;Jun
                                    18, 1:21:35 AM
                                  </td>
                                  <td>0.03</td>
                                  <td>3000</td>
                                  <td>LAGAI</td>
                                  <td>Essex</td>
                                  <td>bs55021</td>

                                  <th>90.0</th>
                                  <th>-3000.0</th>

                                  <td>15.0 %</td>
                                  <th className="runner-sum">13.5</th>
                                  <th className="runner-sum">-450.0</th>

                                  <td>
                                    <span className="label label-primary">
                                      settle
                                    </span>
                                  </td>
                                  <td>13.5</td>
                                </tr>
                                <tr>
                                  <td>14609451</td>
                                  <td>
                                    <i className="fa fa-clock-o"></i>&nbsp;Jun
                                    18, 1:21:32 AM
                                  </td>
                                  <td>0.03</td>
                                  <td>5000</td>
                                  <td>LAGAI</td>
                                  <td>Essex</td>
                                  <td>bs55704</td>

                                  <th>150.0</th>
                                  <th>-5000.0</th>

                                  <td>15.0 %</td>
                                  <th className="runner-sum">22.5</th>
                                  <th className="runner-sum">-750.0</th>

                                  <td>
                                    <span className="label label-primary">
                                      settle
                                    </span>
                                  </td>
                                  <td>22.5</td>
                                </tr>
                                <tr>
                                  <td>14609439</td>
                                  <td>
                                    <i className="fa fa-clock-o"></i>&nbsp;Jun
                                    18, 1:21:26 AM
                                  </td>
                                  <td>0.03</td>
                                  <td>10000</td>
                                  <td>LAGAI</td>
                                  <td>Essex</td>
                                  <td>bs56305</td>

                                  <th>300.0</th>
                                  <th>-10000.0</th>

                                  <td>15.0 %</td>
                                  <th className="runner-sum">45.0</th>
                                  <th className="runner-sum">-1500.0</th>

                                  <td>
                                    <span className="label label-primary">
                                      settle
                                    </span>
                                  </td>
                                  <td>45.0</td>
                                </tr>
                                <tr>
                                  <td>14609438</td>
                                  <td>
                                    <i className="fa fa-clock-o"></i>&nbsp;Jun
                                    18, 1:21:26 AM
                                  </td>
                                  <td>0.03</td>
                                  <td>10000</td>
                                  <td>LAGAI</td>
                                  <td>Essex</td>
                                  <td>bs55700</td>

                                  <th>300.0</th>
                                  <th>-10000.0</th>

                                  <td>15.0 %</td>
                                  <th className="runner-sum">45.0</th>
                                  <th className="runner-sum">-1500.0</th>

                                  <td>
                                    <span className="label label-primary">
                                      settle
                                    </span>
                                  </td>
                                  <td>45.0</td>
                                </tr>
                                <tr>
                                  <td>14609372</td>
                                  <td>
                                    <i className="fa fa-clock-o"></i>&nbsp;Jun
                                    18, 1:20:01 AM
                                  </td>
                                  <td>0.05</td>
                                  <td>100000</td>
                                  <td>LAGAI</td>
                                  <td>Essex</td>
                                  <td>bs55775</td>

                                  <th>5000.0</th>
                                  <th>-100000.0</th>

                                  <td>15.0 %</td>
                                  <th className="runner-sum">750.0</th>
                                  <th className="runner-sum">-15000.0</th>

                                  <td>
                                    <span className="label label-primary">
                                      settle
                                    </span>
                                  </td>
                                  <td>750.0</td>
                                </tr>
                                <tr>
                                  <td>14609325</td>
                                  <td>
                                    <i className="fa fa-clock-o"></i>&nbsp;Jun
                                    18, 1:19:01 AM
                                  </td>
                                  <td>0.06</td>
                                  <td>5000</td>
                                  <td>LAGAI</td>
                                  <td>Essex</td>
                                  <td>bs55865</td>

                                  <th>300.0</th>
                                  <th>-5000.0</th>

                                  <td>15.0 %</td>
                                  <th className="runner-sum">45.0</th>
                                  <th className="runner-sum">-750.0</th>

                                  <td>
                                    <span className="label label-primary">
                                      settle
                                    </span>
                                  </td>
                                  <td>45.0</td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th>Total</th>
                                  <th>81999.64 </th>
                                  <th>-442528.2 </th>
                                  <th></th>
                                  <th>33595.25 </th>
                                  <th>-147109.83 </th>
                                  <th></th>
                                  <th>33595.27</th>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                          <div className="pagination">
                            <span className="previous_page disabled">
                              ← Previous
                            </span>{" "}
                            <em className="current">1</em>{" "}
                            <a
                              rel="next"
                              href="/agent/matches/403636/bet_slips2?page=2"
                            >
                              2
                            </a>{" "}
                            <a
                              className="next_page"
                              rel="next"
                              href="/agent/matches/403636/bet_slips2?page=2"
                            >
                              Next →
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BetSlips2Component;
