import LoginReportComp from 'components/ReportComponent/LoginReportComp';
import React, { Component } from 'react'
import { loginReport } from '_services/loginReport.services';

import { debounce } from "lodash";
export class LoginReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                loginInfo: [],
                loginData: [],
            },
            meta: {
                totalRecords: 0,
                limit: 20,
                currentPage: 1,
                type: 1
            },
            text: ''
        }
    }
    debouncedLog = debounce((text) => this.getLoginReportData(text), 500);
    componentDidMount() {
        this.getLoginReportData()
    }

    async getLoginReportData(text = "") {
        const { limit, currentPage, type } = this.state.meta
        const payload = { type, items_perpage: limit, current_page: currentPage }
        payload["keyword"] = text
        const response = await loginReport(payload)
        try {
            const { login_data = [],login_info = [], total_records = 0 } = response
            this.setState({
                data: {
                    loginInfo: login_info,
                    loginData: login_data
                },
                meta: {
                    ...this.state.meta,
                    totalRecords: total_records,
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    onPageSelect({ selected }) {
        this.setState({
            meta: {
                ...this.state.meta,
                currentPage: selected + 1,
            }
        }, () => {
            this.getLoginReportData()
        })
    }

    onTypeSelect(id) {
        this.setState({
            meta: {
                ...this.state.meta,
                type: id,
                currentPage: 1,
            }
        }, () => {
            this.getLoginReportData()
        })
    }

    handleChange = (e) => {
        this.setState({ keyword: e?.target?.value ? e.target.value : "" });
        this.debouncedLog(e?.target?.value ? e.target.value : "");
    };

    render() {
        return (
            
                <LoginReportComp
                    data={this.state.data}
                    meta={this.state.meta}
                    onPageSelect={(e) => this.onPageSelect(e)}
                    onTypeSelect={(e) => this.onTypeSelect(e)}
                    onSearchText={(e) => this.handleChange(e)}
                    keyword={this.state.keyword}
                />
            
        )
    }
}

export default LoginReport