import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";
import config from "../lib/app-config";

export const profileDetails = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/profile`,
    data
  );
};
export const getAffiliateDashboard = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}affilate_dashboard`,
    data
  );
};
export const getClientDashboard = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}client_dashboard`,
    data
  );
};
export const getDpWdDashboard = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}dp_wd_dashboard`,
    data
  );
};


export const editUser = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/edit_user`,
    data
  );
};

export const getDepositList = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}get_deposit_list`,
    data
  );
};

//////////////////////////////////////////////////////////////

export const getColorsAPI = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}get_colors`,
    data
  );
};