import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";
import config from "../lib/app-config";

export const manualDWReport = (data = {}) => {
    return performRequest(
        apiEndPoints.methodType.POST,
        `${config.api_baseurl}manual_dw_report`,
        data
    );
};
export const depositReq = (data = {}) => {
    return performRequest(
        apiEndPoints.methodType.POST,
        `${config.api_baseurl}deposits`,
        data
    );
};
export const withdrawReq = (data = {}) => {
    return performRequest(
        apiEndPoints.methodType.POST,
        `${config.api_baseurl}withdraw_requests`,
        data
    );
};
export const depositRequest = (data = {}) => {
    return performRequest(
        apiEndPoints.methodType.POST,
        `${config.api_baseurl}deposit_requests`,
        data
    );
};
export const actionDeposit = (data = {}) => {
    return performRequest(
        apiEndPoints.methodType.POST,
        `${config.api_baseurl}action_deposit_request`,
        data
    );
};
export const actionPayout = (data = {}) => {
    return performRequest(
        apiEndPoints.methodType.POST,
        `${config.api_baseurl}action_payout`,
        data
    );
};
