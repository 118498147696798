import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  getBetSlip,
  getMatchPosition,
  getBetListCount,
  deleteBetSlip,
  getEventMarket,
} from "_services";
import ReactPaginate from "react-paginate";
import { userListByKeywordRequest } from "../../_actions/userlistbykeyword/userlistbykeyword.actions";

import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { formatDateAndTime } from "../../utils/index";
import ConfrimModal from "components/ConfirmModal";
import InnerPageHeader from "components/common/InnerPageHeader";
import { addBetTrClass, stackList } from "components/layouts/Helpers";

function MatkaBetSlips(props) {
  const location = useLocation();
  const { matchData, bookmaker_type = 2 } = location.state;
  const navigate = useNavigate()
  const { event_id } = useParams();

  const [betSlipCounts, setBetSlipCounts] = useState("");
  const [betSlipList, setBetSlipList] = useState([]);
  const [matchPositionList, setMatchPositionList] = useState([]);


  const userLogin = JSON.parse(localStorage.getItem("userLogin"));

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(50);
  const [pageCount, setPageCount] = useState(0);
  const [keyword, updateKeyword] = useState("");
  const [userListByKeyword, updateList] = useState([]);
  const [allMarket, updateAllMarket] = useState([]);
  const [selectedMarket, updateSelectedMarket] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState({});
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedStackId, setSelectedStackId] = useState("");

  useEffect(() => {
    if (event_id) {
      fetchBetSlipCount();
      fetchMatchList();
    }
  }, []);

  useEffect(() => {
    if (event_id) {
      let sendData = {};
      sendData.event_id = event_id;
      getEventMarket(sendData)
        .then((response) => {
          if (response && response.data && response.data.data) {
            const allMarketList = response.data.data.market;
            updateAllMarket(allMarketList);
            if (!selectedMarket?.market_id) {
              updateSelectedMarket(allMarketList[0]);
            }
            fetchBetSlip(
              selectedMarket?.market_id
                ? selectedMarket?.market_id
                : allMarketList[0]?.market_id
            );
          }
        })
        .catch((error) => { });
    }
  }, [currentPage]);

  useEffect(() => {
    fetchBetSlip();
  }, [selectedUserId, selectedStackId]);

  const fetchBetSlip = (
    market_id = selectedMarket?.market_id ? selectedMarket?.market_id : ""
  ) => {
    let sendData = {};
    sendData.current_page = currentPage;
    sendData.items_perpage = perPage;
    sendData.bookmaker_type = bookmaker_type;
    sendData.event_id = event_id;
    sendData.market_id = market_id;
    sendData.user_id = selectedUserId;
    sendData.stake_type = selectedStackId;
    getBetSlip(sendData)
      .then((response) => {
        if (response?.data?.data?.bet_data?.length) {
          let dat = JSON.parse(JSON.stringify(response.data.data.bet_data));
          dat.forEach((element) => {
            element.extra_name = getRunnersName(
              element.selection_id,
              element.market_runners
            );
            const extraValue = getExtraValue(element);
            for (const iterator of extraValue) {
              for (const key in iterator) {
                if (Object.hasOwnProperty.call(iterator, key)) {
                  element[key] = iterator[key];
                }
              }
            }

            element.extra_my_share = getMyShare(element.upline_data);
            element.extra_plus_minus = getPlusMinus(
              element.selection_id,
              element
            );
            element.extra_plus_minus =
              (+element.extra_plus_minus).toFixed(2) * -1;
          });

          setBetSlipList(dat);

          setPageCount(Math.ceil(response.data.data.total_records / perPage));
        } else {
          setBetSlipList([]);
          setPageCount(Math.ceil(response.data.data.total_records / perPage));
        }
      })
      .catch((error) => {
        setBetSlipList([]);
      });
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setCurrentPage(selectedPage + 1);
  };

  const fetchBetSlipCount = () => {
    let sendData = {};
    sendData.bookmaker_type = bookmaker_type;
    sendData.event_id = event_id;
    getBetListCount(sendData)
      .then((response) => {
        if (response && response.data && response.data.data) {
          setBetSlipCounts(response.data.data);
        } else {
          setBetSlipCounts("");
        }
      })
      .catch((error) => {
        setBetSlipCounts("");
      });
  };

  const fetchMatchList = () => {
    let sendData = {};
    sendData.event_id = event_id;
    getMatchPosition(sendData)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.length
        ) {
          setMatchPositionList(response.data.data);
        } else {
          setMatchPositionList([]);
        }
      })
      .catch((error) => {
        setMatchPositionList([]);
      });
  };

  const getRunnersName = (selection_id, market_runners) => {
    try {
      if (selection_id && market_runners && market_runners.length) {
        let dat = market_runners.find((val) => val.selectionId == selection_id);
        if (dat && dat.runnerName) {
          return dat.runnerName;
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    } catch (error) { }
  };

  const getValue = (dat, position) => {
    try {
      if (dat.market_runners && dat.market_runners.length) {
        let id =
          dat.market_runners[position] &&
            dat.market_runners[position].selectionId
            ? dat.market_runners[position].selectionId
            : "";
        if (id && dat.runner_pl && dat.runner_pl[id]) {
          return dat.runner_pl[id];
        } else {
          return "";
        }
      }
    } catch (error) { }
  };

  const getExtraValue = (dat) => {
    const share = getMyShare(dat?.upline_data);
    if (dat?.market_runners.length) {
      return dat.market_runners.map((mrt, ind) => {
        let id = mrt.selectionId ? mrt.selectionId : "";
        if (id && dat?.runner_pl[id]) {
          const value = (+dat.runner_pl[id])?.toFixed(2) * -1;
          let percentageValue = (share * value) / 100;
          return {
            ["extra_val_" + (ind + 1)]: value,
            ["extra_after_val_" + (ind + 1)]: (+percentageValue).toFixed(2),
          };
        }
      });
    }
  };

  const getMyShare = (uplineData) => {
    try {
      if (uplineData && uplineData.length) {
        let dat = uplineData.find((val) => val.parent_id == userLogin.user_id);
        if (dat) {
          return dat.my_share;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  };

  const getMyShareAfterData = (dat, position, uplineData) => {
    try {
      let value = getValue(dat, position);
      let share = getMyShare(uplineData);
      let percentageValue = (share * value) / 100;
      return percentageValue;
    } catch (error) {
      return 0;
    }
  };

  const getPlusMinus = (selection_id, dat) => {
    try {
      if (dat && dat.runner_pl) {
        let keys = Object.keys(dat.runner_pl);
        let indexVal = keys.findIndex((val) => val == selection_id);
        if (indexVal !== -1) {
          if (indexVal == 0) {
            return getMyShareAfterData(dat, 0, dat.upline_data);
          } else {
            return getMyShareAfterData(dat, 1, dat.upline_data);
          }
        } else {
          return 0;
        }
      }
    } catch (error) {
      return 0;
    }
  };

  const handleDeleteFn = (event) => {
    if (event) {
      let sendData = {
        betslip_uid: event.betslip_uid,
        user_id: event.user_id,
      };
      setShowDeleteModal(sendData);
    } else if (showDeleteModal.user_id) {
      setShowDeleteModal((state) => ({ ...state, loading: true }));
      deleteBetSlip(showDeleteModal)
        .then((response) => {
          if (response) {
            fetchBetSlip(selectedMarket.market_id);
            setShowDeleteModal({});
          }
        })
        .catch((error) => {
          setShowDeleteModal((state) => ({ ...state, loading: false }));
        });
    }
  };

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
      </>
    );
  };

  const handleInput = (e) => {
    const value = e.target.value;
    updateKeyword(value);
  };

  const onClear = (e) => {
    setCurrentPage(1);
    setSelectedUserId("");
  };

  const handleOnSearch = (string) => {
    if (string.length > 1) {
      updateKeyword(string);
      props.dispatch(
        userListByKeywordRequest({ keyword: string }, (res) => {
          if (res && res.data) {
            updateList(res.data.users);
          }
        })
      );
    }
  };

  const handleOnSelect = (item) => {
    setSelectedUserId(item.user_id);
  };

  const handleChange = (event) => {
    const index = event.target.value;
    updateSelectedMarket(allMarket[index]);
    setCurrentPage(1);
    fetchBetSlip(allMarket[index]?.market_id);
  };

  const getSum = (key) => {
    try {
      let val = betSlipList.reduce((total, value) => {
        return total + (value[key] ? parseFloat(value[key]) : 0);
      }, 0);
      return val.toFixed(2);
    } catch (error) {
      return 0;
    }
  };

  return (
    <div>
      <InnerPageHeader
        pageTitle="Bet Slips"
        breadcrumbs={[
          { title: "Dashboard", url: "/" },
          { title: "Matches", url: "/matches_list" },
          {
            title: matchData?.event_name,
            url: {
              pathname: `${matchData?.sport_id == 6 ? "/games_list" : "/match_dashboard"
                }/${event_id}`,
              state: { matchData: matchData },
            },
          },
        ]}
        currenPageTitle={"Bet Slips"}
      />
      <div className="row">
        <div className="col-lg-3">
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h5>Total Bets</h5>
            </div>
            <div className="ibox-content">
              <h1 className="no-margins">
                {betSlipCounts && betSlipCounts.total_bets
                  ? betSlipCounts.total_bets
                  : 0}
              </h1>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h5>Settled Bets</h5>
            </div>
            <div className="ibox-content">
              <h1 className="no-margins">
                {betSlipCounts && betSlipCounts.settelled_bets
                  ? betSlipCounts.settelled_bets
                  : 0}
              </h1>
            </div>
          </div>
        </div>

        <div className="col-lg-3">
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h5>Unsettled Bets</h5>
            </div>
            <div className="ibox-content">
              <h1 className="no-margins">
                {betSlipCounts && betSlipCounts.unsettelled_bets
                  ? betSlipCounts.unsettelled_bets
                  : 0}
              </h1>
            </div>
          </div>
        </div>

        <div className="col-lg-3">
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h5>Reverted Bets</h5>
            </div>
            <div className="ibox-content">
              <h1 className="no-margins">
                {betSlipCounts && betSlipCounts.cancelled_bets
                  ? betSlipCounts.cancelled_bets
                  : 0}
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div
              className="row"
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div style={{ marginLeft: 20, width: 288, paddingRight: 5 }}>
                <ReactSearchAutocomplete
                  items={userListByKeyword}
                  onSelect={(item) => handleOnSelect(item)}
                  onSearch={(e) => handleOnSearch(e)}
                  onChange={(e) => handleInput(e)}
                  onClear={(e) => onClear(e)}
                  placeholder="Search by user name"
                  className="input-sm form-control input-s-sm inline auto-input"
                  name="search"
                  formatResult={(item) => formatResult(item)}
                />
              </div>

              {matchData?.sport_id !== 6 && (
                <div className="col-sm-3 m-b-xs">
                  <select
                    className="input-sm form-control input-s-sm inline"
                    id="market_ids"
                    name="model[field]"
                    style={{ height: "34px" }}
                    onChange={handleChange}
                  >
                    {allMarket.map((val, indexVal) => (
                      <option key={indexVal} value={indexVal}>
                        {val.market_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div className="col-sm-3 m-b-xs">
                <select
                  className="input-sm form-control input-s-sm inline"
                  id="market_ids"
                  name="model[field]"
                  style={{ height: "34px" }}
                  onChange={(e) => setSelectedStackId(e.target.value)}
                >
                  {stackList.map((val, indexVal) => (
                    <option key={indexVal} value={val?.value}>
                      {val.label} {val?.value ? "Lakhs" : ""}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {matchData?.sport_id != 6 && (
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5>Market Position</h5>
                  <div className="ibox-tools"></div>
                </div>
                <div className="ibox-content table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>RUNNER</th>
                        <th>POSITION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {matchPositionList && matchPositionList.length ? (
                        matchPositionList.map((match, index) => (
                          <tr key={index}>
                            <td>{match.market_name} </td>
                            <td>{match.position}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={2}>No Data Found.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>Bet Slips</h5>
                <div className="ibox-tools"></div>
              </div>

              <div className="ibox-content table-responsive ">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th colSpan={9}>Bet</th>
                      <th colSpan="3">Settlement</th>
                    </tr>
                    <tr>
                      <th>Category</th>
                      <th>Type</th>
                      <th>Patti</th>
                      <th>Number</th>
                      <th>AMOUNT</th>
                      <th>Date</th>
                      <th>Purchase Time</th>
                      <th>Draw Time</th>
                      <th>User</th>
                      <th>Status</th>
                      <th>Plus / Minus</th>
                      <th> Action </th>
                    </tr>
                  </thead>
                  {betSlipList && betSlipList.length ? (
                    <>
                      <tbody>
                        {betSlipList.map((bets, index) => (
                          <tr key={index} className={addBetTrClass(bets.stake)}>
                            <td>{bets?.event_name}</td>
                            <td>
                              {bets?.matka_market_type
                                ? bets?.matka_market_type
                                : ""}
                            </td>
                            <td>{bets.market_name ? bets.market_name : "-"}</td>
                            <td>{bets.team_name}</td>

                            <td>{bets.stake ? bets.stake : "-"}</td>

                            <td>
                              <i className="fa fa-clock-o"></i>&nbsp;
                              {formatDateAndTime(
                                "MMM DD YYYY",
                                bets.created_at
                              )}
                            </td>
                            <td>
                              <i className="fa fa-clock-o"></i>&nbsp;
                              {formatDateAndTime(
                                "MMM DD, hh:mm:ss A",
                                bets.created_at
                              )}
                            </td>
                            <td>
                              <i className="fa fa-clock-o"></i>&nbsp;
                              {formatDateAndTime(
                                "MMM DD, hh:mm:ss A",
                                bets.game_start_date
                              )}
                            </td>
                            <td>
                              <a
                                href="/"
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate(
                                    `/userview/client/${bets.user_guid}/${bets.username}`
                                  );
                                }}
                              >
                                {bets.username} ({bets.name})
                              </a>
                            </td>
                            <td>
                              {bets.winning_status == "0" && (
                                <span className="label label-info">
                                  Pending
                                </span>
                              )}

                              {bets.winning_status == "3" && (
                                <span className="label label-danger">
                                  Cancelled
                                </span>
                              )}

                              {(bets.winning_status == "1" ||
                                bets.winning_status == "2") && (
                                  <span className="label label-primary">
                                    Settle
                                  </span>
                                )}
                            </td>
                            <td>{bets.extra_plus_minus}</td>

                            <td>
                              {userLogin.role == 1 && bets.winning_status == "0" && (
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => handleDeleteFn(bets)}
                                >

                                  DELETE
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colSpan={8}></th>
                          <th>Total</th>
                          <th></th>
                          <th>{getSum("extra_plus_minus")}</th>
                          <th></th>
                        </tr>
                      </tfoot>
                    </>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={15}>No Data Found</td>
                      </tr>
                    </tbody>
                  )}
                </table>

                <div className="row">
                  <div className="float-right"></div>
                </div>

                <div className="paginate_class">
                  <ReactPaginate
                    previousLabel={"prev"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDeleteModal.user_id && (
        <ConfrimModal
          isShow={Boolean(showDeleteModal.user_id)}
          message="Are you sure you want to delete"
          onCancle={() => setShowDeleteModal(false)}
          onSubmit={() => handleDeleteFn()}
          loading={showDeleteModal.loading}
        />
      )}
    </div>
  );
}

export default MatkaBetSlips;
