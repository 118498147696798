import React, { useState, useEffect, Fragment } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import InnerPageHeader from "components/common/InnerPageHeader";
import { getPayemntInfo, updatePayemntInfo } from "_services";
import { useSelector } from "react-redux";
import "./style.css";
import { toast } from "react-toastify";
import { delayList, stakeList } from "components/layouts/Helpers";

function PaymentInfoComponent() {
  const [isLoading, updateLoading] = useState(false);

  const [paymentInfo, updatePaymentInfo] = useState("");

  useEffect(() => {
    getSetting();
  }, []);

  function getSetting() {
    getPayemntInfo()
      .then((response) => {
        if (response && response.data) {
          updatePaymentInfo(response.data.data);
        }
      })
      .catch((error) => { });
  }

  function submitPaymentInfo() {

    if (paymentInfo != null) {
      updateLoading(true);
      const data = {
        ...paymentInfo,
      };
      updatePayemntInfo(data)
        .then((response) => {
          if (response && response.data) {
            toast("Payment Setting updated");
            getSetting();
            setTimeout(() => {
              updateLoading(false);
            }, 2000);
          }
        })
        .catch((error) => {
          updateLoading(false);
        });
    }
  }

  function onSubmit(e) {
    e.preventDefault();
    submitPaymentInfo();
  }

  const onChangeValue = (e, p_t) => {
    const { name, value } = e.target;

    updatePaymentInfo((s) => {
      const newObj = { ...s }
      if (name == "status") {
        let n = newObj[p_t]
        n[name] = n[name] === 1 ? 0 : 1
      }
      else {
        let n = newObj[p_t]
        n[name] = value
      }
      return newObj
    })
  };



  return (
    <>
      <div className="row wrapper border-bottom white-bg page-heading">
        <div className="col-lg-10">
          <h2>Payment Information</h2>
          <ol className="breadcrumb">
            <li>
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item active">
              <strong>Payment Information</strong>
            </li>
          </ol>
        </div>
        <div className="col-lg-2"></div>
      </div>
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>Payment Information Settings</h5>
              </div>
              <div className="ibox-content">


                {paymentInfo && Object.keys(paymentInfo).map((p_type, index) => (
                  <div className="new-box">
                    <div className="heading col-sm-12"><p>{p_type} Details</p></div>
                    {p_type && p_type.length && Object.entries(paymentInfo[p_type]).map(([key, val], idx) => (
                      <Fragment>
                        {key == "status" ?
                          <div className="form-group col-md-6 col-sm-12 d-flex  " style={{ alignItems: 'center' }}>
                            <input
                              type="checkbox"
                              className=""
                              name={key}
                              checked={val == 1 ? true : false}
                              value={val}
                              onChange={(e) => onChangeValue(e, p_type)}
                            />
                            <label className="text-uppercase" style={{ marginLeft: '3px', marginBottom: 0 }}>
                              {key.replace(/_/g, "")}
                            </label>
                          </div>

                          :
                          <div className="form-group col-md-6 col-sm-12">
                            <label className=" control-labe text-uppercase">
                              {key.replace(/_/g, " ")}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name={key}
                              value={val}
                              onChange={(e) => onChangeValue(e, p_type)}
                            />
                          </div>
                        }

                      </Fragment>
                    ))}
                  </div>
                ))}


                <div className="form-group col-sm-12">
                  <div className="d-flex justify-center">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={(e) => !isLoading && onSubmit(e)}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >

      <div>
        <div className="wrapper wrapper-content">
          <div className="row" style={{ margin: "0 !important" }}>
            <div className="match-index-row header-top-spacing">
              <div
                className="row"
                style={{ paddingBottom: "30px", margin: "auto auto" }}
              ></div>
            </div>
          </div>

          <div className="wrapper wrapper-content">
            <div className="row">
              <div className="col-lg-12"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentInfoComponent;
