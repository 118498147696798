// export const userRoleKeys = {
//   sub_company: 2,
//   admin: 3,
//   master: 4,
//   super_stockist: 5,
//   stockist: 6,
//   agent: 7,
//   client: 8,
// };

export const userRoleKeys = {
  company: 1,
  sub_company: 2,
  sub_admin: 3,
  admin: 4,
  super_senior: 5,
  super: 6,
  dealer: 7,
  agent: 8,
  client: 9,
  staff: 10,
};
