import React, { useEffect, useState } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import InnerPageHeader from "components/common/InnerPageHeader";
import { casinoResult } from "_services/casino.services";
const imageURL = 'https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/cards'

export default function GameListComp(props) {
    const { history } = props;
    const location = useLocation();
    const { id } = useParams();
    const { matchData } = location.state;
    const [casinoResultList, setCasinoResultList] = useState([])

    useEffect(() => {
        getCasinoResult()
    }, [])

    const getCardImage = (card) => {
        return `${imageURL}/${card}.png`
    }

    const getCasinoResult = async () => {
        const data = {
            casino_round_id: matchData.casino_round_id
        }
        try {
            let res = await casinoResult(data)
            if (res?.data) {
                setCasinoResultList(res?.data?.casino_result)
            }
        } catch (error) {

        }
    }

    const renderResult = (result) => {
        const cardResult = result.cards.split(',')
        if (result.gtype === 'aaa' || result.gtype === 'lucky7') {
            return (
                <div className='card-container'>
                    <img src={getCardImage(result.cards)} alt="card" />
                </div>
            )
        }
        if (result.gtype === 'teen20' || result.gtype === 'Teen' || result?.gtype === 'ab20') {
            const player_A = []
            const player_B = []
            cardResult.forEach((element, ind) => {
                if (element !== "" && element !== "*")
                    if (ind % 2 === 0) {
                        player_A.push(element);
                    } else {
                        player_B.push(element);
                    }
            });
            return (
                <>
                    <div className='card-container'>
                        <b>{result?.gtype === 'ab20' ? "Andar" : "player A"}:</b> {player_A.map(card => <img src={getCardImage(card)} alt="card" />)}
                    </div>
                    <div className='card-container'>
                        <b>{result?.gtype === 'ab20' ? "Bahar" : "player B"}:</b> {player_B.map(card => <img src={getCardImage(card)} alt="card" />)}
                    </div>
                </>
            )
        }
        if (result.gtype === 'card32' || result.gtype === 'card32eu') {
            const player_8 = []
            const player_9 = []
            const player_10 = []
            const player_11 = []
            cardResult.forEach((element) => {
                switch (true) {
                    case player_8.length === player_9.length && player_8.length === player_10.length && player_8.length === player_11.length:
                        player_8.push(element)
                        break;
                    case (player_8.length > player_9.length && player_9.length === player_10.length && player_9.length === player_11.length):
                        player_9.push(element)
                        break;
                    case (player_8.length === player_9.length && player_9.length > player_10.length && player_10.length === player_11.length):
                        player_10.push(element)
                        break;
                    case (player_8.length === player_9.length && player_9.length === player_10.length && player_10.length > player_11.length):
                        player_11.push(element)
                        break;
                }
            });
            return (
                <>
                    <div className='card-container row1'>
                        <b>player 8: &nbsp; </b> {player_8.filter(p => p !== '1').map(card => <img src={getCardImage(card)} alt="card" />)}
                    </div>
                    <div className='card-container row1'>
                        <b>player 9: &nbsp;</b> {player_9.filter(p => p !== '1').map(card => <img src={getCardImage(card)} alt="card" />)}
                    </div>
                    <div className='card-container row1'>
                        <b>player 10:</b> {player_10.filter(p => p !== '1').map(card => <img src={getCardImage(card)} alt="card" />)}
                    </div>
                    <div className='card-container row1'>
                        <b>player 11:</b> {player_11.filter(p => p !== '1').map(card => <img src={getCardImage(card)} alt="card" />)}
                    </div>
                </>
            )
        }
        if (result.gtype === 'teen9') {
            const tiger = []
            const lion = []
            const dragon = []
            cardResult.forEach((element) => {
                switch (true) {
                    case tiger.length === lion.length && tiger.length === dragon.length:
                        tiger.push(element)
                        break;
                    case (tiger.length > lion.length && lion.length === dragon.length):
                        lion.push(element)
                        break;
                    case (tiger.length === lion.length && lion.length > dragon.length):
                        dragon.push(element)
                        break;
                }
            });
            return (
                <>
                    <div className='card-container row1'>
                        <b>Tiger: &nbsp; &nbsp;</b> {tiger.filter(p => p !== '1').map(card => <img src={getCardImage(card)} alt="card" />)}
                    </div>
                    <div className='card-container row1'>
                        <b>Lion: &nbsp;&nbsp;&nbsp;&nbsp; </b> {lion.filter(p => p !== '1').map(card => <img src={getCardImage(card)} alt="card" />)}
                    </div>
                    <div className='card-container row1'>
                        <b>Dragon:</b> {dragon.filter(p => p !== '1').map(card => <img src={getCardImage(card)} alt="card" />)}
                    </div>
                </>
            )

        }
    }

    return (
        <div>
            <InnerPageHeader
                pageTitle="Casino Game List"
                breadcrumbs={[
                    { title: "Dashboard", url: "/" },
                    { title: "Games List", url: "/game" },
                ]}
                currenPageTitle={matchData.event_name}
            />

            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-title">
                                <h1>Agent Match Dashboard</h1>
                            </div>
                            <div className="ibox-content table-responsive">
                                <p style={{ textAlign: "center" }}>

                                    <Link
                                        to={`/bet_slips/${id}`}
                                        state={{ matchData, bookmaker_type: 4 }}
                                        // href={`/bet_slips/${id}`}
                                        // onClick={(e) => {
                                        //     e.preventDefault();
                                        //     history.push({
                                        //         pathname: `/bet_slips/${id}`,
                                        //         state: { matchData, bookmaker_type: 4 },
                                        //     });
                                        // }}
                                        className="mr-25"
                                    >
                                        <button type="button" className="btn btn-primary btn-lg">
                                            Bet Slip
                                        </button>
                                    </Link>
                                    <Link
                                        to={`/company_report/${id}`}
                                        state={{ matchData }}
                                        // href={`/company_report/${id}`}
                                        // onClick={(e) => {
                                        //     e.preventDefault();
                                        //     // history.push(`/company_report/${id}`);
                                        //     history.push({
                                        //         pathname: `/company_report/${id}`,
                                        //         state: { matchData },
                                        //     });
                                        // }}
                                        className="mr-25"
                                    >
                                        <button type="button" className="btn btn-primary btn-lg">
                                            Company Report
                                        </button>
                                    </Link>

                                    <Link
                                        to={`/client_report/${id}`}
                                        state={{ matchData }}
                                        // href={`/client_report/${id}`}
                                        // onClick={(e) => {
                                        //     e.preventDefault();
                                        //     // history.push("/client_report");
                                        //     history.push({
                                        //         pathname: `/client_report/${id}`,
                                        //         state: { matchData },
                                        //     });
                                        // }}
                                        className="mr-25"
                                    >
                                        <button type="button" className="btn btn-primary btn-lg">
                                            Client Report
                                        </button>
                                    </Link>

                                    <Link
                                        to={`/collection_report/${id}`}
                                        state={{ matchData }}
                                        // href={`/collection_report/${id}`}
                                        // onClick={(e) => {
                                        //     e.preventDefault();
                                        //     // history.push("/collection_report");
                                        //     history.push({
                                        //         pathname: `/collection_report/${id}`,
                                        //         state: { matchData },
                                        //     });
                                        // }}
                                        className="mr-25"
                                    >
                                        <button type="button" className="btn btn-primary btn-lg">
                                            Collection Report
                                        </button>
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                casinoResultList?.map((result, ind) => (
                    <div className='result-container' key={ind + 'text123'}>
                        <div className='round-id'>
                            {result?.mid && <p>Round Id: {result?.mid}</p>}
                        </div>
                        <div className="card-container">
                            <div className="card-container-box">
                                {renderResult(result)}
                                {result?.desc && <p className='result-text'><b>Result:</b> {result?.desc}</p>}
                            </div>
                        </div>
                    </div>
                )
                )
            }
            {/* <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-title">
                                <h5>Match Markets</h5>
                                <div className="ibox-tools"></div>
                            </div>
                            <div className="ibox-content table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Betfair Market Id</th>
                                            <th>Betfair Market Type</th>
                                            <th>RUNNER</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>480666</td>
                                            <td>
                                                <a href="/agent/matches/403636/markets/480666">
                                                    Match Winner_403636
                                                </a>
                                            </td>
                                            <td>Match Winner</td>
                                            <td>2</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-title">
                                <h5>Match Sessions</h5>
                                <div className="ibox-tools"></div>
                            </div>
                            <div className="ibox-content table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Session ID</th>
                                            <th>Session Market</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>63337</td>
                                            <td>
                                                <a href="/agent/matches/403636/sessions/63337">
                                                    15 Overs,Sussex
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>63335</td>
                                            <td>
                                                <a href="/agent/matches/403636/sessions/63335">
                                                    10 Overs,Sussex
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>63329</td>
                                            <td>
                                                <a href="/agent/matches/403636/sessions/63329">
                                                    6 Overs,Sussex
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>63315</td>
                                            <td>
                                                <a href="/agent/matches/403636/sessions/63315">
                                                    20 Overs Lambi,Essex{" "}
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>63325</td>
                                            <td>
                                                <a href="/agent/matches/403636/sessions/63325">
                                                    15 Overs,Essex{" "}
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>63319</td>
                                            <td>
                                                <a href="/agent/matches/403636/sessions/63319">
                                                    10 Overs,Essex{" "}
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>63314</td>
                                            <td>
                                                <a href="/agent/matches/403636/sessions/63314">
                                                    6 Overs,Essex{" "}
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}