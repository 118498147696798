import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

import ReactPaginate from "react-paginate";
import InnerPageHeader from "components/common/InnerPageHeader";
import { depositReq, withdrawReq } from "_services";
import TagAndChips from "components/common/TagAndChips";
import { formatDateAndTime } from "utils";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useScreenDetector } from "hooks/useScreenDetector";
import moment from "moment";

const Deposits = () => {
    const { isMobile } = useScreenDetector();
    const [deposits, setDepositList] = useState([]);
    const [status, setStatus] = useState(1);
    const [pageChange, setPageChange] = useState(1);
    const [perPage] = useState(100);
    const [pageCount, setPageCount] = useState(0);
    const [from_date, setFromDate] = useState(null);
    const [to_date, setToDate] = useState(null);
    const [totalAmount, setTotalAmount] = useState(0);

    useEffect(() => {
        getDepositList(status);
    }, [(from_date && to_date) || pageChange]);

    const getDepositList = (type, isChange) => {
        setTotalAmount(0)
        setStatus(type)
        if (isChange) {
            setPageChange(1)
        }
        let param = {
            status: type,
            current_page: isChange ? 1 : pageChange,
            items_perpage: perPage
        }

        if (from_date && to_date) {
            param["from_date"] = moment(
                moment.utc(from_date).local().format("YYYY-MM-DD 00:00:00"),
                "YYYY-MM-DD HH:mm:ss"
            )
                .utc()
                .format("YYYY-MM-DD HH:mm:ss");
            param["to_date"] = moment(
                moment.utc(to_date).local().format("YYYY-MM-DD 23:59:00"),
                "YYYY-MM-DD HH:mm:ss"
            )
                .utc()
                .format("YYYY-MM-DD HH:mm:ss");
        }

        depositReq(param)
            .then((response) => {
                if (response?.data?.data) {
                    setDepositList(response?.data?.data.records);
                    setPageCount(
                        Math.ceil(response?.data?.data?.total_records / perPage)
                    );

                    let data = response.data.data.records
                    let t_amount = 0
                    data.map((item) => (
                        t_amount = t_amount + item.amount
                    ))
                    setTotalAmount(t_amount)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setPageChange(selectedPage + 1);
    };

    const handleCallback = (from_date, to_date) => {
        setFromDate(from_date);
        setToDate(to_date)
        setPageChange(1);

        // getDepositList(status)
    };


    return (
        <>
            <InnerPageHeader
                pageTitle="DEPOSITS"
                breadcrumbs={[{ title: "Dashboard", url: "/" }]}
                currenPageTitle="DEPOSITS"
            />
            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-12">
                        <div className="ibox">
                            <div className="ibox-title">
                                <h5>Deposits</h5>
                            </div>
                            <div className="ibox-title">
                                <div className={isMobile ? "" : "d-flex"}>
                                    <div className="d-flex justify-start gap-2 overflow-x-scroll">
                                        <TagAndChips className={status == 1 ? "btn btn-primary2" : "btn btn-primary4"} onClick={() => { getDepositList(1, true); }}>
                                            Completed
                                        </TagAndChips>
                                        <TagAndChips className={status == 0 ? "btn btn-primary2" : "btn btn-primary4"} onClick={() => { getDepositList(0, true); }}>
                                            Pending
                                        </TagAndChips>
                                        <TagAndChips className={status == 2 ? "btn btn-primary2" : "btn btn-primary4"} onClick={() => { getDepositList(2, true); }}>
                                            Cancelled
                                        </TagAndChips>
                                    </div>
                                    <DateRangePicker
                                        initialSettings={{
                                            startDate: from_date
                                                ? from_date.toDate()
                                                : moment().subtract(6, "days"),
                                            endDate: to_date ? to_date.toDate() : moment(),
                                            ranges: {
                                                Today: [moment().toDate(), moment().toDate()],
                                                Yesterday: [
                                                    moment().subtract(1, "days").toDate(),
                                                    moment().subtract(1, "days").toDate(),
                                                ],
                                                "Last 7 Days": [
                                                    moment().subtract(6, "days").toDate(),
                                                    moment().toDate(),
                                                ],
                                                "This Week": [
                                                    moment().startOf("isoWeek").toDate(),
                                                    moment().toDate(),
                                                ],
                                                "Last Week": [
                                                    moment().subtract(1, "week").startOf("isoWeek").toDate(),
                                                    moment().subtract(1, "week").endOf("isoWeek").toDate(),
                                                ],
                                                "Last 30 Days": [
                                                    moment().subtract(29, "days").toDate(),
                                                    moment().toDate(),
                                                ],
                                                "This Month": [
                                                    moment().startOf("month").toDate(),
                                                    moment().toDate(),
                                                ],
                                                "Last Month": [
                                                    moment().subtract(1, "month").startOf("month").toDate(),
                                                    moment().subtract(1, "month").endOf("month").toDate(),
                                                ],
                                            },
                                        }}
                                        onCallback={handleCallback}
                                    >
                                        <div
                                            id="reportrange"
                                            style={{
                                                padding: 10,
                                                background: "none repeat scroll 0% 0% #fff",
                                                cursor: "pointer",
                                                padding: "5px 10px",
                                                border: "1px solid #ccc",
                                                margin: " 10px 0px 0px 25px",
                                                maxWidth: isMobile ? "100%" : "25%",
                                            }}
                                            className="col-lg-3 col-md-3 col-sm-9 mlDatePicke"
                                        >
                                            <span
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    padding: 0,
                                                }}
                                            >
                                                <i
                                                    className={"glyphicon glyphicon-calendar  fa fa-calendar"}
                                                ></i>
                                                &nbsp;&nbsp;
                                                <span className="text-right">
                                                    {from_date && to_date
                                                        ? `${from_date.format("DD MMM, YYYY")} - ${to_date.format(
                                                            "DD MMM, YYYY"
                                                        )}`
                                                        : "-"}
                                                </span>
                                                &nbsp;&nbsp;
                                                <i className="fa fa-caret-down" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                    </DateRangePicker>
                                </div>
                            </div>
                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-responsive  table-striped dataTables-example">
                                        <thead>
                                            <tr>
                                                <th>Customer Name</th>
                                                <th>Email</th>
                                                <th>Mobile</th>
                                                <th>Amount</th>
                                                <th>Option</th>
                                                <th>Status</th>
                                                <th>Date</th>

                                            </tr>
                                        </thead>
                                        {deposits && deposits.length > 0 ? <tbody>
                                            {deposits?.map((element, ind) => {
                                                return (
                                                    <tr key={ind}>
                                                        <td>{element.username && element.username != "" ? element.username : element.customer_name}</td>
                                                        <td>{element.customer_email}</td>
                                                        <td>{element.customer_mobile}</td>
                                                        <td>{element.amount}</td>
                                                        <td>{element.option}</td>
                                                        <td>{element.status == 0 ? 'Pending' : element.status == 1 ? "Completed" : element.status == 2 ? "Cancelled" : ""}</td>
                                                        <td> {element.created_at ? formatDateAndTime("MMM DD, hh:mm:ss A", element.created_at) : "-"}</td>
                                                    </tr>
                                                );
                                            })}
                                            <tr>
                                                <th>Total</th>
                                                <th></th>
                                                <th></th>
                                                <th>{totalAmount}</th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </tbody> :
                                            <tfoot >
                                                <tr><th className="text-center" colSpan={6}> No record found</th></tr>
                                            </tfoot>
                                        }
                                    </table>

                                    <div className="paginate_class">
                                        <ReactPaginate
                                            previousLabel={"prev"}
                                            nextLabel={"next"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Deposits;
