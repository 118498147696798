import config from "../lib/app-config";
import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";

export const getBonusList = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}list_bonus_offers`,
    data
  );
};

export const AddBonus = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}add_bonuns_offer`,
    data
  );
};
export const ActiveInactiveBonusOffers = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}active_inactive_offer`,
    data
  );
};
export const DeleteBonus = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}delete_offer`,
    data
  );
};