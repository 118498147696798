import React, { useState, useEffect } from 'react'
import { UpdateWhatsappNo, getOtherAppCharges } from "_services/othercharges.services"
import { UpdateOtherAppCharges } from "_services/othercharges.services"
import { toast } from "react-toastify";
import InnerPageHeader from 'components/common/InnerPageHeader';

function UpdateWhatsapp() {

    const [otherChargesData, setotherChargesData] = useState({
        id: '',
        whatsapp_number: '',
    })

    useEffect(() => {
        getOtherChargesFunction();
    }, [])

    const getOtherChargesFunction = () => {
        let sendData = {}
        getOtherAppCharges(sendData)
            .then((res) => {
                setotherChargesData(res?.data?.data)
            }).catch((err) => {
                console.log(err)
            })
    }

    const handleInput = (e) => {
        setotherChargesData({
            ...otherChargesData,
            [e.target.name]: e.target.value
        })
    }

    var onFormSubmit = (e) => {
        e.preventDefault();

        let sendData = {
            id: otherChargesData.id,
            whatsapp_number: otherChargesData.whatsapp_number
        }
        UpdateWhatsappNo(sendData)
            .then((res) => {
                // toast("Other Charges Data Updated");
                toast.success(
                    <div>
                        <i
                            className="fa fa-check"
                            style={{ fontWeight: "bold", marginRight: "1rem" }}
                            aria-hidden="true"
                        ></i>
                        <span>Whatsapp Number Updated</span>
                    </div>,
                    {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    }
                );
            }).catch((err) => {
                toast.error("Not Working, Whatsapp Number Not Updated");
            })
    }

    return (
        <>
            <InnerPageHeader
                pageTitle="Update Whatsapp"
                breadcrumbs={[
                    { title: "Dashboard", url: "/" },
                ]}
                currenPageTitle="Update Whatsapp"
            />

            <div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-content">
                                <form className="form-horizontal">
                                    <div className="form-group">
                                        <label className="col-sm-3 control-label">Whatsapp No</label>
                                        <div className="col-sm-6">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="whatsapp_number"
                                                value={otherChargesData.whatsapp_number}
                                                onChange={(e) => handleInput(e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="hr-line-dashed"></div>

                                    <div className="form-group">
                                        <div className="col-sm-4 col-sm-offset-2" style={{ marginLeft: "391px" }}>
                                            <button className="btn btn-primary" onClick={(e) => onFormSubmit(e)}>
                                                Update Whatsapp
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateWhatsapp


// getOtherAppCharges

// updateOtherAppCharges