import React from "react";
import switch_on from "../../images/on-button.png"
import switch_off from "../../images/off-button.png"


function SessionView(props) {
  const {
    fancy = [],
    userRole,
    fancyStatusChange,
    fetchFancyPosition,
    onClickPositionModal,
  } = props;
  return (
    <>

      <div className="session-container">
        <div className="session-header">
          <div className="col-sm-8 session">
            <p className="session-name">
              {+userRole?.role === 1 || +userRole?.role === 10 ? (
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={fancy?.data.some((item) => !item?.bet_lock)}
                    onChange={(e) =>
                      fancyStatusChange(
                        e,
                        +fancy?.session_type === 1
                          ? ["1", "2", "3", "4"]
                          : [fancy?.session_type],
                        fancy?.data.some((item) => !item?.bet_lock) ? 0 : 1
                      )
                    }
                  />
                  <span className="slider round"></span>
                </label>
              ) : (
                <></>
              )}
              {" "}Pending Sessions</p>
          </div>
        </div>
        <div className="market-header">
          <div className="col-sm-8 market">
            <p className="market-name">{fancy?.session?.name ? fancy?.session?.name.toUpperCase() : ""}</p>
            {/* <p className="stack">Min:100 Max:100000</p> */}
          </div>
          <div className="col-sm-2 lagai">No</div>
          <div className="col-sm-2 khai">Yes</div>
        </div>

        {
          <>
            {fancy?.data.map((session, index) => (
              <div className="market-body" key={index}>
                <div className="col-lg-8 runner">
                  <div className=" runner-n-pos">
                    {
                      userRole?.role == 1 || userRole?.role == 10 ? (
                        <img height={35} src={session.bet_lock == "1" ? switch_off : switch_on}
                          onClick={(e) =>
                            fancyStatusChange(
                              e,
                              session.session_id,
                              session.bet_lock
                            )
                          }
                        />
                        // <label className="switch">
                        //   <input
                        //     id={`fancy-switch-new${session.session_id}`}
                        //     type="checkbox"
                        //     checked={!session.bet_lock}
                        //     onChange={(e) =>
                        //       fancyStatusChange(
                        //         e,
                        //         session.session_id,
                        //         session.bet_lock
                        //       )
                        //     }
                        //   />
                        //   <span className="slider round"></span>
                        // </label>
                      ) : null
                    }{" "}
                    <div className="runner-block">
                      <p className="runner-name">
                        {session?.runner_name
                          ? session.runner_name.slice(0, 30)
                          : ""}
                      </p>
                      <p className="win-loss">
                        <span>Pos. no. </span>
                        <span
                          style={{
                            color:
                              fetchFancyPosition(session.session_id, "no") >= 0
                                ? "#228C22"
                                : "#FF0000",
                          }}
                        >{fetchFancyPosition(session.session_id, "no")}</span>
                        {"  "}
                        <span>Pos. yes. </span>
                        <span
                          style={{
                            color:
                              fetchFancyPosition(session.session_id, "yes") >= 0
                                ? "#228C22"
                                : "#FF0000",
                          }}
                        >{fetchFancyPosition(session.session_id, "yes")}</span>
                      </p>
                    </div>
                  </div>
                  <div className="book-btn">
                    <a
                      className="btn btn-primary"
                      data-method="get"
                      data-remote="true"
                      onClick={() => onClickPositionModal(session)}
                    >
                      Book
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 odds">
                  <div className="col-lg-6 lagai">
                    <div className="lagai-block">
                      <p className="price">{session.lay_price1}</p>
                      <p className="size">{session.lay_size1}</p>
                    </div>
                  </div>
                  <div className="col-lg-6 khai">
                    <div className="khai-block">
                      <p className="price">{session.back_price1}</p>
                      <p className="size">{session.back_size1}</p>
                    </div>
                  </div>
                </div>
              </div>

            ))}
          </>}

      </div>

      {/* 
      <div className="session-container">
        <div className="session-header">
          <div className="col-sm-8 session">
            <p className="session-name">
              {+userRole?.role === 1 || +userRole?.role === 9 ? (
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={fancy?.data.some((item) => !item?.bet_lock)}
                    onChange={(e) =>
                      fancyStatusChange(
                        e,
                        +fancy?.session_type === 1
                          ? ["1", "2", "3", "4"]
                          : [fancy?.session_type],
                        fancy?.data.some((item) => !item?.bet_lock) ? 0 : 1
                      )
                    }
                  />
                  <span className="slider round"></span>
                </label>
              ) : (
                <></>
              )}
              {" "}Pending Sessions</p>
          </div>
        </div>
        <div className="fancy-header">
          <table className="table session-table">
            <thead>
              <tr>
                <th >{fancy?.session?.name ? fancy?.session?.name.toUpperCase() : ""}</th>
                <th>No</th>
                <th>Yes</th>
                <th>Position No</th>
                <th>Position Yes</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {fancy?.data.map((session, index) => (
                <tr key={index}>
                  <td className="d-flex">
                    <div>
                      {
                        userRole?.role == 1 || userRole?.role == 9 ? (
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={!session.bet_lock}
                              onChange={(e) =>
                                fancyStatusChange(
                                  e,
                                  session.session_id,
                                  session.bet_lock
                                )
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                        ) : null
                      }{" "}
                    </div>
                    <div className="runner_name">
                      <span>
                        {session?.runner_name
                          ? session.runner_name.slice(0, 30)
                          : ""}
                      </span>
                    </div>

                  </td>
                  <td>
                    <div className="lagai-block">
                      <p className="price">{session.lay_price1}</p>
                      <p className="size">{session.lay_size1}</p>
                    </div>
                  </td>
                  <td>
                    <div className="khai-block">
                      <p className="price">{session.back_price1}</p>
                      <p className="size">{session.back_size1}</p>
                    </div>
                  </td>

                  <td
                    style={{
                      color:
                        fetchFancyPosition(session.session_id, "no") >= 0
                          ? "#228C22"
                          : "#FF0000",
                    }}
                  >{fetchFancyPosition(session.session_id, "no")}</td>
                  <td
                    style={{
                      color:
                        fetchFancyPosition(session.session_id, "yes") >= 0
                          ? "#228C22"
                          : "#FF0000",
                    }}
                  >{fetchFancyPosition(session.session_id, "yes")}</td>
                  <td>
                    <a
                      className="btn btn-primary"
                      data-method="get"
                      data-remote="true"
                      onClick={() => onClickPositionModal(session)}
                    >
                      Book
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div> */}


      {/* <div className="row session-resp-tbl table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th
                colSpan="10"
                className="bet-place-tbl-th market_type_row session-th-strip align-left"
              >
                <div className="lock_toggle_box">
                  {+userRole?.role === 1 || +userRole?.role === 9 ? (
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={fancy?.data.some((item) => !item?.bet_lock)}
                        onChange={(e) =>
                          fancyStatusChange(
                            e,
                            +fancy?.session_type === 1
                              ? ["1", "2", "3", "4"]
                              : [fancy?.session_type],
                            fancy?.data.some((item) => !item?.bet_lock) ? 0 : 1
                          )
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  ) : (
                    <></>
                  )}
                  <span>PENDING SESSIONS</span>
                </div>
              </th>
            </tr>
            <tr>
              <th
                className="bet-place-tbl-th"
                style={{ backgroundColor: "#49494A" }}
              >
                {" "}
                {fancy?.session?.name ? fancy?.session?.name.toUpperCase() : ""}
              </th>
              <th className="bet-odds-type bet-place-tbl-th">No</th>
              <th
                className="bet-place-tbl-th"
                style={{ backgroundColor: "#49494A" }}
              >
                RATE
              </th>
              <th className="bet-odds-type bet-place-tbl-th">Yes</th>
              <th
                className="bet-place-tbl-th"
                style={{ backgroundColor: "#49494A" }}
              >
                RATE
              </th>
              <th className="bet-odds-type bet-place-tbl-th">Position NO</th>
              <th className="bet-odds-type bet-place-tbl-th">Position Yes</th>
              <th
                className="bet-place-tbl-th"
                style={{ backgroundColor: "#49494A" }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {fancy?.data.map((session, index) => (
              <tr key={index}>
                <td
                  data-column="session_title"
                  className="bet-place-tbl-td "
                  style={{ textAlign: "left" }}
                >
                  <div className="lock_toggle_box">
                    {
                      // role && role == "1"
                      userRole?.role == 1 || userRole?.role == 9 ? (
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={!session.bet_lock}
                            onChange={(e) =>
                              fancyStatusChange(
                                e,
                                session.session_id,
                                session.bet_lock
                              )
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      ) : null
                    }{" "}
                    <span>
                      {session?.runner_name
                        ? session.runner_name.slice(0, 30)
                        : ""}
                    </span>
                  </div>
                </td>
                <td
                  data-column="no_runs"
                  className="SessionTable bet-place-tbl-td first-runner-bet-odds-yes-value cursor-pointer close-status"
                  data-id="NO"
                  match-id="405125"
                  session-run-id="2302722"
                  session-id="63411"
                  id="63411_no"
                >
                  {session.lay_price1}
                </td>
                <td
                  data-column="no_rate"
                  id="63411_no_rate"
                  className="no_rate bet-place-tbl-td bet-val-cent close-status"
                >
                  {session.lay_size1}
                </td>
                <td
                  data-column="yes_runs"
                  className="SessionTable bet-place-tbl-td first-runner-bet-odds-no-value cursor-pointer close-status"
                  data-id="YES"
                  match-id="405125"
                  session-run-id="2302722"
                  session-id="63411"
                  id="63411_yes"
                >
                  {session.back_price1}
                </td>
                <td
                  data-column="yes_rate"
                  className="yes_rate bet-place-tbl-td bet-val-cent close-status"
                  id="63411_yes_rate"
                >
                  {session.back_size1}
                </td>
                <td
                  className="bet-place-tbl-td bet-val-cent"
                  id="session_no_runns_63411"
                  style={{
                    color:
                      fetchFancyPosition(session.session_id, "no") >= 0
                        ? "#228C22"
                        : "#FF0000",
                  }}
                >
                  {fetchFancyPosition(session.session_id, "no")}
                </td>
                <td
                  className="bet-place-tbl-td bet-val-cent"
                  id="session_yes_runns_63411"
                  style={{
                    color:
                      fetchFancyPosition(session.session_id, "yes") >= 0
                        ? "#228C22"
                        : "#FF0000",
                  }}
                >
                  {fetchFancyPosition(session.session_id, "yes")}
                </td>
                <td className="bet-place-tbl-td">
                  <a
                    className="btn btn-info"
                    data-method="get"
                    data-remote="true"
                    onClick={() => onClickPositionModal(session)}
                  >
                    POSITION
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}
    </>
  );
}

export default SessionView;
