import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { smoothlyMenu } from "../layouts/Helpers";
import $ from "jquery";
import { logoutUser } from "../../_actions";
import { useDispatch } from "react-redux";
import { IoPersonSharp } from "react-icons/io5";

const TopHeader = (props) => {

  const [state, setState] = useState({
    redirect: "",
    isToggle: false,
  });
  const dispatch = useDispatch()
  const userLogin = JSON.parse(localStorage.getItem("userLogin"));

  const getRole = () => {
    let role = localStorage.getItem("role") || null;
    switch (role) {
      case "1":
        return "Company";

      case "2":
        return "Sub Company";

      case "3":
        return "Sub Admin";

      case "4":
        return "Admin";

      case "5":
        return "Super Senior";

      case "6":
        return "Super";

      case "7":
        return "Dealer";

      case "8":
        return "Agent";

      case "9":
        return "Client";

      case "10":
        return "Staff";

      default:
        return "User";
    }
  };


  useEffect(() => {
    applySidebarClassName();
  }, []);

  const toggleNavigation = () => {
    $("body").toggleClass("mini-navbar");
    applySidebarClassName();
  };

  const applySidebarClassName = () => {
    if ($(".mini-navbar").length > 0) {
      $("#scrollable-container").addClass("scrollable-menu");
      $("#scrollable-container").removeClass("scrollable-container");
    } else {
      $("#scrollable-container").addClass("scrollable-container");
      $("#scrollable-container").removeClass("scrollable-menu");
    }

    smoothlyMenu();
  };

  const logOut = () => {
    dispatch(
      logoutUser((result) => {
        if (result) {
          setState({ redirect: "login" });
        } else {
          setState({ redirect: "login" });
        }
      })
    );
  };
  let { redirect } = state;
  if (redirect == "login") {
    return <Navigate to={"/login"} />;
  }


  return (
    <div className="row border-bottom anvMain_nav">


      <nav
        className="navbar navbar-static-top white-bg"
        id="top-navbar"
        role="navigation"
        style={{
          marginBottom: 0,
        }}
      >
        <div className="navbar-header">
          <a
            className="navbar-minimalize minimalize-styl-2  "
            onClick={() => toggleNavigation()}
          >
            <i className="fa fa-bars"></i>{" "}
          </a>
        </div>


        {/* User Credentials JSX */}
        <div id="user-dropdown-data">

          <div id="user-logo">
            <IoPersonSharp size={'20px'} color="white" />
          </div>

          <div id="user-credentials">
            <span className="block m-t-xs">
              {" "}
              <strong className="font-bold" style={{ fontSize: '15px' }}>
                {userLogin?.username}({userLogin?.first_name}){""}
              </strong>
            </span>{" "}
            <span className="text-muted text-xs block">
              {getRole() == "Agent" ? `My Share:${' '}${userLogin.my_agent_share}%` : getRole()}
            </span>{" "}
          </div>



          <ul className="nav" style={{ paddingRight: '20px', cursor: 'pointer' }}>
            <li>
              <i className="fa fa-sign-out" onClick={logOut}
                style={{ fontSize: '20px' }}></i>
            </li>
          </ul>

        </div>

      </nav>
    </div>
  );
};

// export default connect(mapStateToProps,null,null,{forwardRef:true})(SignInModal);

export default TopHeader;
