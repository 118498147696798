import React, { Component } from "react";
import InnerPageHeader from "../common/InnerPageHeader";
import { listRequest } from "../../_actions/master/master.actions";
import {
  betLockUnLock,
  casinolockUnlock,
  activeInactiveUsers,
  list,
} from "../../_services/master.services";
import { toast } from "react-toastify";


import { debounce } from "lodash";

import ReactPaginate from "react-paginate";
import { Link, NavLink } from "react-router-dom";

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: props?.role,
      keyword: "",
      UserData: {},
      confirmEvent: "",

      masterList: [],
      totalRecords: "",
      currentPage: 1,
      perPage: 10,
      pageCount: 0,

    };
  }

  debouncedLog = debounce((text) => this.fetchMasterData(text), 500);

  componentDidMount() {
    this.fetchMasterData(this.state.keyword);
    this.scrollToTop();
  }

  fetchMasterData = (searchData = "") => {
    let sendObj = {
      user_guid: "",
      current_page: this.state.currentPage,
      items_perpage: this.state.perPage,
      keyword: searchData,
      role: this.state.role,
    };
    this.props.dispatch(
      listRequest(sendObj, (res) => {
        if (res && res.data) {
          this.setState({
            masterList: res.data.userdownlist,
          });

          this.setState({
            totalRecords: res.data.total_records,
          });

          this.setState({
            pageCount: Math.ceil(res.data.total_records / this.state.perPage),
          });
        } else {
          this.setState({
            masterList: [],
            totalRecords: 0,
          });
        }
      })
    );
  };

  getRecordDetails = () => {
    const { currentPage, totalRecords } = this.state;
    let startingCount =
      this.state.currentPage === 1
        ? 1
        : this.state.currentPage * this.state.perPage - 9;
    let endingCount =
      this.state.perPage < totalRecords
        ? this.state.currentPage === 1
          ? this.state.perPage
          : this.state.currentPage * 10
        : totalRecords;
    return `Showing ${startingCount} to ${endingCount} of ${totalRecords} entries`;
  };

  handleChange = (e) => {
    this.setState({ keyword: e.target.value });
    this.debouncedLog(e.target.value);
  };

  statusChange = (confirmEvent, user, ind) => {
    let sendData = {};
    sendData.user_guid = user.user_guid;
    sendData.status = user.status == "0" ? 1 : 0;

    activeInactiveUsers(sendData)
      .then((response) => {
        if (response) {
          let sendObj = {
            user_guid: user.user_guid,
            current_page: this.state.currentPage,
            items_perpage: this.state.perPage,
            keyword: this.state.keyword,
            role: this.state.role,
          };

          list(sendObj)
            .then((response) => {
              this.setState({ masterList: response.data.data.userdownlist });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  lockUnlockBet = (event, user, ind) => {
    let sendData = {};
    sendData.user_guid = user.user_guid;
    sendData.lock_betting = user.lock_betting == "0" ? 1 : 0;

    betLockUnLock(sendData)
      .then((response) => {
        if (response) {
          let sendObj = {
            user_guid: user.user_guid,
            current_page: this.state.currentPage,
            items_perpage: this.state.perPage,
            keyword: this.state.keyword,
            role: this.state.role,
          };

          list(sendObj)
            .then((response) => {
              this.setState({ masterList: response.data.data.userdownlist });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  lockUnlockCasino(event, user, ind) {
    if (event) {
      let sendData = {};
      sendData.user_guid = user.user_guid;
      sendData.lock_casino_bet = user.lock_casino_bet == "0" ? 1 : 0;
      try {
        casinolockUnlock(sendData)
          .then((response) => {
            if (response) {
              let sendObj = {
                user_guid: user.user_guid,
                current_page: this.state.currentPage,
                items_perpage: this.state.perPage,
                keyword: this.state.keyword,
                role: this.state.role,
              };

              list(sendObj)
                .then((response) => {
                  this.setState({
                    masterList: response.data.data.userdownlist,
                  });
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error, "error");
      }
    }
  }

  scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document,
        behavior: "smooth",
      });
    }, 1000);
    return true;
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      this.fetchMasterData(this.state.keyword);
    });
  };

  statusChange = (confirmEvent, user, ind) => {
    let sendData = {};
    sendData.user_guid = user.user_guid;
    sendData.status = user.status == "0" ? 1 : 0;

    activeInactiveUsers(sendData)
      .then((response) => {
        if (response) {
          this.fetchMasterData();
        }
      })
      .catch((error) => {
        toast.error("Error");
      });
  };

  lockUnlockBet = (event, user, ind) => {
    let sendData = {};
    sendData.user_guid = user.user_guid;
    sendData.lock_betting = user.lock_betting == "0" ? 1 : 0;

    betLockUnLock(sendData)
      .then((response) => {
        if (response) {
          this.fetchMasterData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  lockUnlockCasino(event, user, ind) {
    if (event) {
      let sendData = {};
      sendData.user_guid = user.user_guid;
      sendData.lock_casino_bet = user.lock_casino_bet == "0" ? 1 : 0;
      try {
        casinolockUnlock(sendData)
          .then((response) => {
            if (response) {
              this.fetchMasterData();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error, "error");
      }
    }
  }



  render() {
    const { masterList, totalRecords, currentPage, perPage, pageCount } =
      this.state;
    const { title, navigationUrl } = this.props;


    return (
      <>
        <InnerPageHeader
          pageTitle={`${title} List`}
          breadcrumbs={[
            { title: "Dashboard", url: "/" },
            { title: title, url: { navigationUrl } },
          ]}
          currenPageTitle="List"
        />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5 style={{ fontWeight: 700 }}>All {title}</h5>
                  <div className="ibox-tools">
                    <Link
                      to={navigationUrl + "/add"}
                      className="btn btn-primary btn-xs"
                      style={{ cursor: "pointer" }}
                    >
                      Create {title}
                    </Link>
                  </div>
                </div>
                <div className="ibox-content">
                  <div className="table-responsive">
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper form-inline dt-bootstrap"
                    >
                      <div className="dt-buttons btn-group">
                        <a
                          className="dt-button buttons-csv buttons-html5"
                          tabIndex="0"
                          aria-controls="DataTables_Table_0"
                          href="#"
                          style={{ marginRight: "0.5rem" }}
                        >
                          <span>CSV</span>
                        </a>
                        <a
                          className="dt-button buttons-pdf buttons-html5"
                          tabIndex="0"
                          aria-controls="DataTables_Table_0"
                          href="#"
                        >
                          <span>PDF</span>
                        </a>
                      </div>

                      <div
                        id="DataTables_Table_0_filter"
                        className="dataTables_filter"
                      >
                        <label>
                          Search:
                          <input
                            type="search"
                            className="form-control input-sm"
                            placeholder=""
                            aria-controls="DataTables_Table_0"
                            value={this.state.keyword}
                            onChange={this.handleChange}
                          />
                        </label>
                      </div>

                      <table className="table table-striped table-hover dataTables-example">
                        <thead>
                          <tr>
                            <th>S.No </th>
                            <th>User Name</th>
                            {/* <th>Name</th> */}
                            <th>Fix Limit</th>
                            <th>My Share</th>
                            <th>Max Share</th>
                            <th>Casino Share</th>

                            <th>User Lock</th>
                            <th>Bet Lock</th>
                            <th>Casino Lock</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {masterList && masterList.length ? (
                            masterList.map((user, index) => (
                              <tr className="gradeX" key={index}>
                                {/* <td>{user.agent_id}</td> */}
                                <td>
                                  {index + 1 + (currentPage - 1) * perPage}
                                </td>
                                <td>
                                  <a
                                    href="/"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.navigate(
                                        `/userview${navigationUrl}/${user.user_guid}/${user.username}`
                                      );
                                    }}
                                  >
                                    {user.username} {user.first_name && (`(${user.first_name})`)}
                                  </a>
                                </td>
                                {/* <td>
                                  {user.first_name} {user.last_name}
                                </td> */}
                                <td className="center">{user.fixed_limit}</td>
                                <td className="center">{user.my_share}</td>
                                <td className="center">
                                  {user.my_agent_share}
                                </td>
                                <td className="center">{user.game_share}</td>



                                <td className="center">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={user.status == "1"}
                                      onChange={(e) =>
                                        this.statusChange(e, user, false)
                                      }
                                    />
                                    <span className="slider round"></span>
                                  </label></td>
                                <td className="center">

                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        user.lock_betting == "0"
                                      }
                                      onChange={(e) =>
                                        this.lockUnlockBet(e, user, false)
                                      }
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </td>
                                <td className="center">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        user.lock_casino_bet == "0"
                                      }
                                      onChange={(e) =>
                                        this.lockUnlockCasino(e, user, false)
                                      }
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </td>
                                <td className="">
                                  <Link
                                    to={`${navigationUrl}/edit/${user?.user_guid}`}
                                    state={{ selectedData: user }}
                                    className="btn btn-white btn-sm"
                                  >
                                    <i className="fa fa-pencil-square"></i> Edit
                                  </Link>
                                  &nbsp;&nbsp; &nbsp;&nbsp;

                                  <a
                                    href="/"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.navigate(
                                        `/edit_password/${user.user_guid}`
                                      );
                                    }}

                                    className="btn btn-white btn-sm"
                                  >
                                    <i className="fa fa-key"></i> CP
                                  </a>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="gradeX">
                              <td colSpan={7}>No data available in table</td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      <div className="row" style={{ margin: "15px 0px" }}>
                        <div className="col-xs-12 col-sm-6">
                          <div
                            className="dataTables_info"
                            id="DataTables_Table_0_info"
                            role="status"
                            aria-live="polite"
                          >
                            {totalRecords
                              ? this.getRecordDetails()
                              : "Showing 0 to 0 of 0 entries"}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                          <div className="paginate_class">
                            <ReactPaginate
                              previousLabel={"prev"}
                              nextLabel={"next"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={this.state.pageCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={this.handlePageClick}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"}
                            />
                          </div>
                        </div>
                        <div className="clearfix visible-xs"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}
export default List;
