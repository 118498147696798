import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";
import config from "../lib/app-config";

export const getUplinestoCreateUser = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}get_uplines_to_create_user`,
    data
  );
};

export const depositBalance = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}deposit_balance`,
    data
  );
};

export const withdrawBalance = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}withdraw_balance`,
    data
  );
};


export const getDirectDownline = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}direct_downline`,
    data
  );
};