import React, { useEffect, useState } from "react";
import { companyAnnouncement } from "_services/announcement.services";
import Mic from "../../images/Vector.png"

function Banner(props) {
  const [announcement, setAnnouncement] = useState("");
  useEffect(() => {
    getCompanyAnnouncement();
  }, [props.toggleBanner]);

  const getCompanyAnnouncement = () => {
    companyAnnouncement()
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.admin_agent_msg
        ) {
          setAnnouncement({
            admin_agent_msg: response.data.data.admin_agent_msg,
            user_msg: response.data.data.user_msg,
          });
        } else {
          setAnnouncement("");
        }
      })
      .catch(() => {
        setAnnouncement("");
      });
  };
  const clientAnnouncementView = () => {
    const list = [];
    for (const key in announcement) {
      if (Object.hasOwnProperty.call(announcement, key)) {
        list.push(
          <li className="px-2">
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {announcement[key]}
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          </li>
        );
      }
    }
    return list;
  };

  return (
    <>
      <div className="row border-bottom">
        <ul className="marquee d-flex">
          <span className="mic"><img src={Mic} /></span>
          <marquee>{clientAnnouncementView()}</marquee>
        </ul>
      </div>
    </>
  );
}
export default Banner;
