import React, { useEffect, useState } from "react";
import Progress from "../common/Progress";
import Navigation from "../common/Navigation";
import Footer from "../common/Footer";
import TopHeader from "../common/TopHeader";
import { correctHeight, detectBody } from "./Helpers";
import $ from "jquery";
import Banner from "components/common/Banner";
import { useLocation, useNavigate } from "react-router-dom";

export const BannerContext = React.createContext();
export const BannerProvider = BannerContext.Provider;
export const BannerConsumer = BannerContext.Consumer;

const Main = ({ children }) => {
  const [state, setState] = useState({
    toggleBanner: true,
  });

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Run correctHeight function on load and resize window event
    $(window).bind("load resize", function () {
      correctHeight();
      detectBody();
    });

    // Correct height of wrapper after metisMenu animation.
    $(".metismenu a").click(() => {
      setTimeout(() => {
        correctHeight();
      }, 300);
    });
  }, []);

  const handleToggle = () => {
    setState((prevState) => ({
      toggleBanner: !prevState.toggleBanner,
    }));
  };

  let wrapperClass = "gray-bg ";

  return (
    <BannerProvider
      value={{
        toggleBanner: state.toggleBanner,
        setToggleBaner: handleToggle,
      }}
    >
      <div id="wrapper">
        <Progress />
        <Navigation location={location} navigate={navigate}/>

        <div id="page-wrapper" className={wrapperClass}>
          <TopHeader />
          <Banner toggleBanner={state.toggleBanner} />
          {children}

          <Footer />
        </div>
      </div>
    </BannerProvider>
  );
};
export default Main;
