import React, { useState, useEffect } from 'react'
import { UpdateHTD, getOtherAppCharges } from "_services/othercharges.services"
import { UpdateOtherAppCharges } from "_services/othercharges.services"
import { toast } from "react-toastify";
import InnerPageHeader from 'components/common/InnerPageHeader';

function HowToDeposit() {

    const [otherChargesData, setotherChargesData] = useState({
        id: '',
        how_to_deposit: '',
    })

    useEffect(() => {
        getOtherChargesFunction();
    }, [])

    const getOtherChargesFunction = () => {
        let sendData = {}
        getOtherAppCharges(sendData)
            .then((res) => {
                setotherChargesData(res?.data?.data)
            }).catch((err) => {
                console.log(err)
            })
    }

    const handleInput = (e) => {
        setotherChargesData({
            ...otherChargesData,
            [e.target.name]: e.target.value
        })
    }

    var onFormSubmit = (e) => {
        e.preventDefault();

        let sendData = {
            id: otherChargesData.id,
            how_to_deposit: otherChargesData.how_to_deposit
        }
        UpdateHTD(sendData)
            .then((res) => {
                // toast("Other Charges Data Updated");
                toast.success(
                    <div>
                        <i
                            className="fa fa-check"
                            style={{ fontWeight: "bold", marginRight: "1rem" }}
                            aria-hidden="true"
                        ></i>
                        <span>videoURL Updated</span>
                    </div>,
                    {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    }
                );
            }).catch((err) => {
                toast.error("Not Working, Whatsapp Number Not Updated");
            })
    }

    return (
        <>
            <InnerPageHeader
                pageTitle="How to Deposit"
                breadcrumbs={[
                    { title: "Dashboard", url: "/" },
                ]}
                currenPageTitle="How to Deposit"
            />

            <div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-content">
                                <div style={{ marginBottom: '30px' }}>
                                    <h2><strong>How to get youtube video ID</strong></h2>
                                    <p>YouTube Video URL: <a href="https://www.youtube.com/watch?v=pcU_iAJkXZY" target="_blank" rel="noopener noreferrer">https://www.youtube.com/watch?v=pcU_iAJkXZY</a></p>
                                    <p>Video ID: pcU_iAJkXZY</p>
                                    <p>You can copy the video ID directly from the URL after the "v=" parameter. In this case, the video ID is "pcU_iAJkXZY".</p>
                                </div>
                                <hr style={{ borderTopStyle: "solid" }} />
                                <form className="form-horizontal">
                                    <div className="form-group">
                                        <label className="col-sm-3 control-label">YouTube Video ID</label>
                                        <div className="col-sm-6">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="how_to_deposit"
                                                value={otherChargesData.how_to_deposit}
                                                onChange={(e) => handleInput(e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="hr-line-dashed"></div>

                                    <div className="form-group">
                                        <div className="col-sm-4 col-sm-offset-2" style={{ marginLeft: "391px" }}>
                                            <button className="btn btn-primary" onClick={(e) => onFormSubmit(e)}>
                                                Update videoURL
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HowToDeposit


// getOtherAppCharges

// updateOtherAppCharges