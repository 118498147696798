
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { coinsReport } from "../../_services/casino.services";
import ReactPaginate from "react-paginate";
import moment from "moment";
import InnerPageHeader from "components/common/InnerPageHeader";
import { BonusCoinReport } from "_services/affiliate.services";

const BonusTransaction = () => {
    const [changePage, setChangePage] = useState(1);
    const [coins, setCoins] = useState([]);
    const [perPage] = useState(50);
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
        getCoinReport();
    }, [changePage]);

    const getCoinReport = () => {
        const profileData = JSON.parse(localStorage.getItem("userLogin"))

        let sendObj = {};
        sendObj.current_page = changePage;
        sendObj.items_perpage = perPage;
        sendObj.user_guid = profileData.user_guid;

        BonusCoinReport(sendObj)
            .then((response) => {
                if (response && response?.data?.data) {
                    setCoins(response?.data?.data?.coin_report);

                    // setPageCount(Math.ceil(response?.data?.data?.coin_report.length / perPage))

                    setPageCount(Math.ceil(response?.data?.data?.total / perPage));
                }
            })
            .catch((error) => {
                console.log(error, "error");
            });
    };

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setChangePage(selectedPage + 1);
    };

    return (
        <>
            <InnerPageHeader
                pageTitle={"BONUS TRANSACTION"}
                breadcrumbs={[
                    { title: "Dashboard", url: "/" },
                    //   { title: type, url: `/userview/${type}/${id}/${username}` },
                ]}
                currenPageTitle="Bonus Transaction"
            />
            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-title">
                                {/* <h5> {props?.params.username} </h5> */}
                            </div>
                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Transaction ID</th>
                                                <th>Description</th>
                                                <th>Coins</th>
                                                <th> User Balance </th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {coins &&
                                                coins.map((element, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{element.order_no}</td>
                                                            <td>{element.remark}</td>
                                                            <td>
                                                                {element.credit_amount != 0
                                                                    ? element.credit_amount
                                                                    : element.debit_amount}
                                                            </td>
                                                            <td> {element.user_balance} </td>
                                                            <td>
                                                                {moment
                                                                    .utc(element.created_at)
                                                                    .local()
                                                                    .format("YYYY-MM-DD HH:mm a")}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="paginate_class">
                                    <ReactPaginate
                                        previousLabel={"prev"}
                                        nextLabel={"next"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default BonusTransaction;
