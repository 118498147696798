import $ from "jquery";
export function correctHeight() {
  var pageWrapper = $("#page-wrapper");
  var navbarHeight = $("nav.navbar-default").height();
  var wrapperHeigh = pageWrapper.height();

  if (navbarHeight > wrapperHeigh) {
    pageWrapper.css("min-height", navbarHeight + "px");
  }

  if (navbarHeight < wrapperHeigh) {
    if (navbarHeight < $(window).height()) {
      pageWrapper.css("min-height", $(window).height() + "px");
    } else {
      pageWrapper.css("min-height", navbarHeight + "px");
    }
  }

  if ($("body").hasClass("fixed-nav")) {
    if (navbarHeight > wrapperHeigh) {
      pageWrapper.css("min-height", navbarHeight + "px");
    } else {
      pageWrapper.css("min-height", $(window).height() - 60 + "px");
    }
  }
}

export function detectBody() {
  if ($(document).width() < 769) {
    $("body").addClass("body-small");
  } else {
    $("body").removeClass("body-small");
  }
}

export function smoothlyMenu() {
  if (!$("body").hasClass("mini-navbar") || $("body").hasClass("body-small")) {
    // Hide menu in order to smoothly turn on when maximize menu
    $("#side-menu").hide();
    // For smoothly turn on menu
    setTimeout(function () {
      $("#side-menu").fadeIn(400);
    }, 200);
  } else if ($("body").hasClass("fixed-sidebar")) {
    $("#side-menu").hide();
    setTimeout(function () {
      $("#side-menu").fadeIn(400);
    }, 100);
  } else {
    // Remove all inline style from jquery fadeIn function to reset menu state
    $("#side-menu").removeAttr("style");
  }
}

export const addBetTrClass = (value = "") => {
  switch (true) {
    case value >= 1000000:
      return "tr-danger";
    case value >= 500000:
      return "tr-warning";
    case value >= 100000:
      return "tr-success";
    default:
      return "s";
  }
};

export const getFilterTypeMarketList = (type, allMarket = []) => {
  let filteredList = [];
  for (let i = 0; i < allMarket.length; i++) {
    if (type == 2) {
      if (allMarket[i].market_type == 6 || allMarket[i].market_type == 7) {
        filteredList.push(allMarket[i]);
      }
    } else if (type == 1) {
      if (allMarket[i].market_type != 6 && allMarket[i].market_type != 7) {
        filteredList.push(allMarket[i]);
      }
    }
  }
  return filteredList;
};
export const detectMobView = () => {
  return window.innerWidth <= 800 && window.innerHeight <= 915;
};

const sessionType = {
  // 1: { position: 1, name: "Session", value: "_fancy_stack" },
  // 6: { position: 2, name: "Fancy", value: "_fancy_run_stack" },
  // 7: { position: 3, name: "Ball By Ball", value: "_ball_by_ball_stack" },
  // 5: { position: 4, name: "Others", value: "_other_fancy_stack" },
  1: { position: 1, name: "Session", value: "_fancy_stack" },
  2: { position: 2, name: "Session run 2", value: "_fancy_stack" },
  3: { position: 3, name: "Fall of wicket", value: "_fancy_stack" },
  4: { position: 4, name: "Player run", value: "_fancy_stack" },
  5: { position: 5, name: "Others", value: "_other_fancy_stack" },
  6: { position: 6, name: "Fancy", value: "_fancy_run_stack" },
  7: { position: 7, name: "Ball By Ball", value: "_ball_by_ball_stack" },
  8: { position: 8, name: "Advance", value: "_advance_fancy_stack" },
};

// export const differentFancyList = (list) => {
//   const fancyArray = list.reduce((itemArray, item) => {
//     const itemValue = {
//       session_type: item?.session_type <= 4 ? 1 : item?.session_type,
//       session: sessionType[item?.session_type <= 4 ? 1 : item?.session_type],
//       data: [item],
//     };
//     if (itemArray.length > 0) {
//       const ind = itemArray.findIndex((i) =>
//         i.session_type === 1
//           ? item?.session_type <= 4
//           : i.session_type === item?.session_type
//       );
//       if (ind >= 0) {
//         const itemList = itemArray[ind]?.data;
//         itemList.push(item);
//         itemArray.splice(ind, 1, {
//           ...itemArray[ind],
//           data: itemList,
//         });
//       } else {
//         itemArray.push(itemValue);
//       }
//     } else {
//       itemArray.push(itemValue);
//     }
//     return itemArray;
//   }, []);
//   return fancyArray.sort((a, b) => a?.session?.position - b?.session?.position);
// };

export const differentFancyList = (list) => {
  const fancyArray = list.reduce((itemArray, item) => {
    const session_type = item?.session_type ? item?.session_type : 1;
    const itemValue = {
      session_type: session_type,
      session: sessionType[session_type],
      data: [item],
    };
    if (itemArray.length > 0) {
      const ind = itemArray.findIndex((i) => i.session_type === item?.session_type
      );
      if (ind >= 0) {
        const itemList = itemArray[ind]?.data;
        itemList.push(item);
        itemArray.splice(ind, 1, {
          ...itemArray[ind],
          data: itemList,
        });
      } else {
        itemArray.push(itemValue);
      }
    } else {
      itemArray.push(itemValue);
    }
    return itemArray;
  }, []);
  return fancyArray.sort((a, b) => a?.session?.position - b?.session?.position);
};

export const stakeList = [
  { label: "Min Match odds Stake", name: "min_match_stack" },
  { label: "Max Match odds Stake", name: "max_match_stack" },
  { label: "Min Session Stake", name: "min_fancy_stack" },
  { label: "Max Session Stake", name: "max_fancy_stack" },
  { label: "Min Manual Stake", name: "min_other_market_stack" },
  { label: "Max Manual Stake", name: "max_other_market_stack" },
  { label: "Min Bookmaker Stake", name: "min_bookmaker_stack" },
  { label: "Max Bookmaker Stake", name: "max_bookmaker_stack" },
  { label: "Min Cup Stake", name: "min_cup_stack" },
  { label: "Max Cup Stake", name: "max_cup_stack" },
  { label: "Min Other Stake", name: "min_other_fancy_stack" },
  { label: "Max Other Stake", name: "max_other_fancy_stack" },
  { label: "Min Fancy Stake", name: "min_fancy_run_stack" },
  { label: "Max Fancy Stake", name: "max_fancy_run_stack" },
  { label: "Min Ball by Ball Stake", name: "min_ball_by_ball_stack" },
  { label: "Max Ball by Ball Stake", name: "max_ball_by_ball_stack" },
  { label: "Min Advance Fancy Stake", name: "min_advance_fancy_stack" },
  { label: "Max Advance Fancy Stake", name: "max_advance_fancy_stack" },
  { label: "Max Cup Profit", name: "max_cup_profit" },
];

export const delayList = [
  { label: "Match Odd Delay", name: "match_delay" },
  { label: "Bookmaker Delay", name: "bookmaker_delay" },
  { label: "Cup Delay", name: "cup_delay" },
  { label: "Manual Delay", name: "other_market_delay" },
  { label: "Session Run Delay", name: "session_run_delay" }, // 1
  { label: "Session Run2 Delay", name: "session_run2_delay" }, // 2
  { label: "Fall of Wicket Delay", name: "fall_of_wicket_delay" }, // 3
  { label: "Player Run Delay", name: "player_run_delay" }, // 4
  { label: "Other Delay", name: "other_fancy_delay" }, // 5
  { label: "Fancy Delay", name: "fancy_run_delay" }, // 6
  { label: "Ball by Ball Delay", name: "ball_by_ball_delay" },
  { label: "Advance Fancy Delay", name: "advance_fancy_delay" },
];

export const stackList = [
  {
    label: "Select stack amount",
    value: "",
  },
  {
    label: "1 - 5 ",
    value: 1,
  },
  {
    label: "5 - 10 ",
    value: 2,
  },
  {
    label: "10 - 15 ",
    value: 3,
  },
  {
    label: "15 - 20 ",
    value: 4,
  },
  {
    label: "20 - 25 ",
    value: 5,
  },
];

export const awaitApiCallFuncation = (array = [], fun, destroy = false) => {
  let promise = Promise.resolve();
  if (array) {
    array.forEach((el, ind) => {
      promise = promise.then(() => {
        fun(el, ind);
        return new Promise((resolve) => {
          const timeOut = setTimeout(resolve, 100);
          if (destroy) {
            clearTimeout(timeOut);
          }
        });
      });
    });
  }
};

export const isAscending = (numbers) => {
  let accendingNum = false;
  numbers.split("").reduce((num, number, index) => {
    if (num <= number) {
      if (+num === 0 && num <= number) {
        accendingNum = false;
      } else {
        accendingNum = true;
      }
      if (+num === 0 && index === 2) {
        accendingNum = true;
      }
    } else {
      if (+number === 0 && index === 2) {
        accendingNum = true;
      } else {
        accendingNum = false;
        return;
      }
    }
    num = number;
    return num;
  }, numbers.split("")[0]);
  return accendingNum;
};
