import { AffiliateRequests } from '_services/affiliate.services';
import InnerPageHeader from 'components/common/InnerPageHeader';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

const AffiliateRequest = () => {
    const [affiliateList, setAffiliateList] = useState([]);
    const [current_page, setCurrentPage] = useState(1);
    const [items_perpage] = useState(100);
    const [total_records, setTotalPage] = useState(null);

    useEffect(() => {
        getAffiliateRequest();
    }, []);

    const getAffiliateRequest = () => {
        let param = {
            current_page,
            items_perpage
        }
        AffiliateRequests(param)
            .then((response) => {
                if (response?.data?.data) {
                    setAffiliateList(response?.data?.data?.records);
                    setTotalPage(response?.data?.data?.total_records);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };




    return (
        <div >
            <InnerPageHeader
                pageTitle="AFFILIATE REQUESTS"
                breadcrumbs={[{ title: "Dashboard", url: "/" }]}
                currenPageTitle="AFFILIATE REQUESTS"
            />

            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-12">
                        <div className="ibox">
                            <div className="ibox-title">
                                <h5>Affiliate Requests List</h5>

                            </div>

                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-responsive  table-striped dataTables-example">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Mobile</th>
                                                <th>Email</th>
                                                <th>Created At</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {affiliateList?.map((item, ind) => {
                                                return (
                                                    <tr key={ind}>
                                                        <td>{item.name}</td>
                                                        <td>{item.mobile}</td>
                                                        <td>{item.email}</td>
                                                        <td>
                                                            {moment
                                                                .utc(item.created_at)
                                                                .local()
                                                                .format("DD-MMM-YYYY HH:mm a")}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>

                                    {/* <div className="paginate_class">
                                        <ReactPaginate
                                            previousLabel={"prev"}
                                            nextLabel={"next"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={items_perpage}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            // onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"}
                                        />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default AffiliateRequest;
