import React from "react";
import "./ManageLedger.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

import ReactPaginate from "react-paginate";
import InnerPageHeader from "components/common/InnerPageHeader";

export default function CashBook() {
  return (
    <>
      <InnerPageHeader
        pageTitle="Cashbook"
        breadcrumbs={[{ title: "Dashboard", url: "/" }]}
        currenPageTitle="Cashbook"
      />
      <div className="row">
        <DateRangePicker
          initialSettings={{
            startDate: Date(),
            endDate: Date(),
            ranges: {
              Today: [moment().toDate(), moment().toDate()],
              Yesterday: [
                moment().subtract(1, "days").toDate(),
                moment().subtract(1, "days").toDate(),
              ],
              "Last 7 Days": [
                moment().subtract(6, "days").toDate(),
                moment().toDate(),
              ],
              "This Week": [
                moment().startOf("isoWeek").toDate(),
                moment().toDate(),
              ],
              "Last Week": [
                moment().subtract(1, "week").startOf("isoWeek").toDate(),
                moment().subtract(1, "week").endOf("isoWeek").toDate(),
              ],
              "Last 30 Days": [
                moment().subtract(29, "days").toDate(),
                moment().toDate(),
              ],
              "This Month": [
                moment().startOf("month").toDate(),
                moment().toDate(),
              ],
              "Last Month": [
                moment().subtract(1, "month").startOf("month").toDate(),
                moment().subtract(1, "month").endOf("month").toDate(),
              ],
            },
          }}
          // onCallback={this.handleCallback}
        >
          <div
            id="reportrange"
            className="col-lg-3 col-md-3 col-sm-9 cbDatePicker"
          >
            <i className={"glyphicon glyphicon-calendar  fa fa-calendar"}></i>
            &nbsp;&nbsp;
            {/* <span className="text-right">{from_date.format('DD MMM, YYYY') + ' - ' + to_date.format('DD MMM, YYYY')}</span>&nbsp;&nbsp; */}
            <i className="fa fa-caret-down" aria-hidden="true"></i>
          </div>
        </DateRangePicker>
        <div
          className="col-lg-3 col-md-4 col-sm-6 col-sm-3"
          style={{ margin: "11px 0px" }}
        >
          <a className="btn btn-primary btn-xs search_date_btn">Search</a>
        </div>
      </div>
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>Cashbook</h5>
              </div>
              <div className="ibox-content">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="table-responsive">
                      <table className="table table-striped table-bordered table-hover dataTables-example">
                        <thead>
                          <tr>
                            <th>DATE/TIME</th>
                            <th>User Name</th>
                            <th>Debit</th>
                            <th>Credit</th>
                            <th>Remark</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                          </tr>
                        </tbody>
                      </table>

                      <div className="paginate_class">
                        <ReactPaginate
                          previousLabel={"prev"}
                          nextLabel={"next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          // pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          // onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
