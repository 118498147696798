import React, { Component } from "react";
import InnerPageHeader from "../common/InnerPageHeader";
import { userListByKeywordRequest } from "../../_actions/userlistbykeyword/userlistbykeyword.actions";
import { userUplinesByUserIdRequest } from "../../_actions/useruplinesbyuserid/useruplinesbyuserid.actions";
import { Button, Modal } from "react-bootstrap";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Setting from "./../../images/setting.png";
import {
  getDelaySetting,
  updateDelaySetting,
  deleteDelaySetting,
} from "_services";
import { toast } from "react-toastify";
import { delayList } from "components/layouts/Helpers";

class GlobalSearch extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    sendObj: {
      keyword: "",
    },
    sendUserObj: {
      user_id: "",
    },
    userUplinesByUserId: [],
    showForm: "none",
    keyword: "",
    userListByKeyword: [],
    record_list: [],
    username: "",
    role: "",
    name: "",
    role_type_list: [],
    my_share: "",
    my_comission: 0,
    showSettingModal: false,
    delayValue: "",
    showRest: false,
  };
  componentDidMount() {
    this.state.role_type_list = [
      "Company",
      "Sub Company",
      "Master",
      "Super Stockist",
      "Stockist",
      "Agent",
      "Client",
    ];
  }

  /*getsearchedautocompletelist = (e) =>{
    
  };*/

  showForm = (e) => {
    e.preventDefault();
    this.state.showForm = "block";
  };

  handleInput = (e) => {
    const { name, value } = e.target; // <-- moved outside asynchronous context

    this.setState((state) => ({
      sendObj: {
        ...state.formObj,
        [name]: value,
      },
    }));
  };

  onClear = (e) => {
    this.setState({ userUplinesByUserId: [] });
    // setCurrentPage(1);
    // fetchBetSlip();
  };

  handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    if (string.length > 0) {
      this.state.sendObj.keyword = string;
      this.props.dispatch(
        userListByKeywordRequest(this.state.sendObj, (res) => {
          if (res && res.data) {
            this.setState({
              userListByKeyword: res.data.users,
              //totalRecords: res.data.total_records,
            });
          }
        })
      );
    }
  };

  handleOnSelect = (item) => {
    //get users uplines by user id
    this.state.sendUserObj.user_id = item.user_id;
    //alert(item.user_id);
    this.props.dispatch(
      userUplinesByUserIdRequest(this.state.sendUserObj, (res) => {
        if (res && res.data) {
          this.setState({
            userUplinesByUserId: res.data.users,
            //totalRecords: res.data.total_records,
          });
          res.data.users.map((item, i) => {
            if (i == 0) {
              this.setState({
                my_comission: item.my_match_commission,
                my_share: item.my_share,
              });
            }
          });
        }
      })
    );

    // the item selected 
    this.setState({
      username: item.username,
      name: item.name,
      role: item.role,
    });
  };

  formatResult = (item) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
      </>
    );
  };

  updateState = (e) => {
    const { name, value } = e.target;
    this.setState({ delayValue: { ...this.state.delayValue, [name]: value } });
  };

  onOpenSettingClick = (userId) => {
    let sendData = {};
    sendData.user_id = userId;
    getDelaySetting(sendData)
      .then((response) => {
        if (response && response.data && response.data.data) {
          this.setState({
            selectedUserId: userId,
            delayValue: response.data.data,
            showRest: response.data.data.match_delay,
            showSettingModal: true,
          });
        }
      })
      .catch((error) => { });
  };

  hideConfirmModal = () => {
    this.setState({ showSettingModal: false });
  };

  resetSetting = () => {
    let sendData = {};
    sendData.user_id = this.state.selectedUserId;
    deleteDelaySetting(sendData)
      .then((response) => {
        if (response && response.data && response.data.data) {
          this.setState({
            delayValue: response.data.data,
            showSettingModal: false,
          });
        }
      })
      .catch((error) => { });
    // this.setState({showSettingModal:false})
  };

  onUpdateClick = () => {
    const checkDelay = delayList.filter(
      (item) =>
        this.state.delayValue[item?.name] === "" ||
        this.state.delayValue[item?.name] === null
    );
    if (checkDelay.length > 0) {
      toast.error(checkDelay[0]?.label + " require");
      return;
    } else {
      let sendData = {
        user_id: this.state.selectedUserId,
        ...this.state.delayValue,
      };

      updateDelaySetting(sendData)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.setState({ selectedUserId: "", showSettingModal: false });
          }
        })
        .catch((error) => { });
    }
  };

  render() {
    // const { manualmarketList} = this.state;
    const { userListByKeyword, showSettingModal } = this.state;
    const items = userListByKeyword;
    return (
      <>
        <InnerPageHeader
          pageTitle="Global Search"
          breadcrumbs={[
            { title: "Dashboard", url: "/" },
            { title: "Global Search", url: "/global-search" },
          ]}
          currenPageTitle="Global Search"
        />

        <div
          className="wrapper wrapper-content animated fadeInRight"
          style={{ paddingBottom: "0px" }}
        >
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title gbl-srch">
                  <form className="form-inline">
                    <div className="form-group mb-2">
                      <label>User Name&nbsp;&nbsp;</label>
                    </div>
                    <div className="form-group mx-sm-3 mb-2">
                      <div
                        style={{ width: 288, paddingTop: 15, paddingRight: 5 }}
                      >
                        <ReactSearchAutocomplete
                          items={items}
                          onSelect={this.handleOnSelect}
                          onSearch={this.handleOnSearch}
                          onChange={this.handleInput}
                          onClear={this.onClear}
                          autoFocus
                          placeholder="Search by user name"
                          className="form-group"
                          name="search"
                          formatResult={this.formatResult}
                        />
                        &nbsp;&nbsp;
                      </div>
                    </div>
                  </form>
                </div>
                <div className="ibox-content">
                  <div className="table-responsive">
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper form-inline dt-bootstrap"
                    >
                      <table className="table table-striped table-bordered table-hover dataTables-example">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Username</th>
                            <th>Name</th>
                            <th>Role Type</th>
                            <th>My Share</th>
                            <th>My Comission</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.userUplinesByUserId ? (
                            this.state.userUplinesByUserId.map(
                              (item, index) => (
                                <tr className="gradeX" key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.username}</td>
                                  <td>{item.name}</td>
                                  <td>{item.role}</td>
                                  <td>
                                    {item.role === "2"
                                      ? item.company_share
                                      : item.my_agent_share}
                                  </td>
                                  <td>{item.my_match_commission}</td>
                                  {item.role == 7 && (
                                    <td className="pointer">
                                      <img
                                        onClick={() =>
                                          this.onOpenSettingClick(item.user_id)
                                        }
                                        src={Setting}
                                      />
                                    </td>
                                  )}
                                </tr>
                              )
                            )
                          ) : (
                            <tr>
                              <td colSpan={7} style={{ textAlign: "center" }}>
                                No Data found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      <div className="row" style={{ margin: "15px 0px" }}>
                        <div className="clearfix visible-xs"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={showSettingModal}
          onHide={() => this.hideConfirmModal()}
          style={{ opacity: 1 }}
        >
          <Modal.Header closeButton>Sport Setting</Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <div className="row">
                {delayList.map((item) => (
                  <div className="col-sm-6" key={item?.label}>
                    <div className="form-group">
                      <label className="">{item?.label}</label>
                      <input
                        name={item?.name}
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        value={this.state.delayValue[item?.name]}
                        onChange={(e) => this.updateState(e)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {this.state.showRest && (
              <Button onClick={() => this.resetSetting()}>Reset</Button>
            )}
            <Button onClick={() => this.hideConfirmModal()}>Cancel</Button>
            <Button type="submit" onClick={this.onUpdateClick}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
export default GlobalSearch;
