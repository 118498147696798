import React, { Component, Fragment } from "react";
import InnerPageHeader from "../common/InnerPageHeader";
import { listRequest } from "../../_actions/client/client.actions";
import ReactPaginate from "react-paginate";
import { debounce } from "lodash";
import { Link } from "react-router-dom";


import {
  betLockUnLock,
  casinolockUnlock,
  activeInactiveUsers,
  list,
} from "../../_services/master.services";
import { UpdateBalanceModal } from "modals";
import { toast } from "react-toastify";
import { depositBalance, withdrawBalance } from "_services/common.services";





class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: localStorage.getItem("role") || null,
      clientList: [],
      totalRecords: 0,
      role: 9,
      currentPage: 1,
      currentlyPage: 1,
      perPage: 20,
      pageCount: 0,
      records_all: "",
      keyword: "",
      UpdateBalanceModalShow: false,
      eventLoading: false,
      UpdateBalanceModalTitle: null,
      selectedUser: null
    };

    this.getTodos = this.getTodos.bind(this);
  }



  debouncedLog = debounce((text) => this.getTodos(text), 500);

  componentDidMount() {
    this.getTodos();
  }

  getTodos = () => {
    let sendObj = {
      user_guid: "",
      current_page: this.state.currentlyPage,
      items_perpage: this.state.perPage,
      keyword: this.state.keyword,
      role: this.state.role,
    };
    this.props.dispatch(
      listRequest(sendObj, (res) => {
        if (res && res.data) {
          this.setState({
            clientList: res.data.userdownlist,
            totalRecords: res.data.total_records,
            records_all: Math.ceil(res.data.total_records / this.state.perPage),
          });
        }
      })
    );
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    this.setState({ currentlyPage: selectedPage + 1 }, () => {
      this.getTodos();
    });
  };

  handleChange = (e) => {
    this.setState({ keyword: e.target.value });
    this.debouncedLog(e.target.value);
  };

  statusChange = (confirmEvent, user, ind) => {
    let sendData = {};
    sendData.user_guid = user.user_guid;
    sendData.status = user.status == "0" ? 1 : 0;

    activeInactiveUsers(sendData)
      .then((response) => {
        // this.setState.masterList[ind].user.status = (user.status == "0" ? 1 : 0);

        if (response) {
          let sendObj = {
            user_guid: user.user_guid,
            current_page: this.state.currentPage,
            items_perpage: this.state.perPage,
            keyword: this.state.keyword,
            role: this.state.role,
          };

          this.getTodos();
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data && error.response.data.error && error.response.data.error != "") {
          toast.error(error.response.data.error)
        } else {
          console.log(error);
        }
      });
  };

  lockUnlockBet = (event, user, ind) => {
    let sendData = {};
    sendData.user_guid = user.user_guid;
    sendData.lock_betting = user.lock_betting == "0" ? 1 : 0;

    betLockUnLock(sendData)
      .then((response) => {
        if (response) {
          let sendObj = {
            user_guid: user.user_guid,
            current_page: this.state.currentPage,
            items_perpage: this.state.perPage,
            keyword: this.state.keyword,
            role: this.state.role,
          };

          this.getTodos();
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data && error.response.data.error && error.response.data.error != "") {
          toast.error(error.response.data.error)
        } else {
          console.log(error);
        }
      });
  };

  lockUnlockCasino(event, user, ind) {
    if (event) {
      let sendData = {};
      sendData.user_guid = user.user_guid;
      sendData.lock_casino_bet = user.lock_casino_bet == "0" ? 1 : 0;
      try {
        casinolockUnlock(sendData)
          .then((response) => {
            if (response) {
              let sendObj = {
                user_guid: user.user_guid,
                current_page: this.state.currentPage,
                items_perpage: this.state.perPage,
                keyword: this.state.keyword,
                role: this.state.role,
              };

              this.getTodos();
            }
          })
          .catch((error) => {

            if (error && error.response && error.response.data && error.response.data.error && error.response.data.error != "") {
              toast.error(error.response.data.error)
            } else {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error, "error");
      }
    }
  }

  getRecordDetails = () => {
    const { currentPage, totalRecords } = this.state;
    let startingCount =
      this.state.currentPage === 1
        ? 1
        : this.state.currentPage * this.state.perPage - 9;
    let endingCount =
      this.state.perPage < totalRecords
        ? this.state.currentPage === 1
          ? this.state.perPage
          : this.state.currentPage * 10
        : totalRecords;
    return `Showing ${startingCount} to ${endingCount} of ${totalRecords} entries`;
  };


  setToggleBalanceModal = (item, type) => {
    this.setState({ selectedUser: item, UpdateBalanceModalTitle: type, UpdateBalanceModalShow: true })
  }

  modalHide = () => {
    this.setState({ UpdateBalanceModalShow: false })
  }

  updateUserBalance = async (amount) => {
    const profileData = JSON.parse(localStorage.getItem("userLogin"))
    const paload = {
      user_guid: this.state.selectedUser?.user_guid,
      amount: amount,
    };
    this.setState({ eventLoading: true });
    if (this.state.UpdateBalanceModalTitle === "Withdraw") {
      try {
        const res = await withdrawBalance(paload);
        this.handleResponse(res, profileData.balance + amount);
      } catch (error) {
        this.setState({ eventLoading: false });
        this.modalHide()
      }
    }

    if (this.state.UpdateBalanceModalTitle === "Deposit") {
      try {
        const res = await depositBalance(paload);
        this.handleResponse(res, profileData.balance - amount);
      } catch (error) {
        this.setState({ eventLoading: false });
        this.modalHide()
      }
    }
  };

  handleResponse = (res, amount) => {

    if (res?.data?.message) {
      toast.success(res?.data?.message);
      let currentUser = JSON.parse(localStorage.getItem("userLogin"))
      currentUser.balance = amount
      localStorage.setItem("userLogin", JSON.stringify(currentUser));
      this.getTodos();
    }
    if (res?.data?.error) toast.error(res?.data?.error);
    this.modalHide();
    this.setState({ eventLoading: false });
  };

  render() {
    const {
      clientList,
      totalRecords,
      perPage,
      pageCount,
      records_all,
      currentPage,
      eventLoading,
      selectedUser,
      UpdateBalanceModalTitle,
      UpdateBalanceModalShow
    } = this.state;

    const profileData = localStorage.getItem("userLogin")
      ? +JSON.parse(localStorage.getItem("userLogin")).balance
      : 0;

    const UpdateBalanceModalProps = {
      title: UpdateBalanceModalTitle,
      isShow: selectedUser,
      parentBalance: profileData,
      modalToggle: this.modalHide,
      onSubmit: (amount) => this.updateUserBalance(amount),
      loading: eventLoading,
      cancleText: "Cancel",
    }

    return (
      <>
        <InnerPageHeader
          pageTitle="Client List"
          breadcrumbs={[
            { title: "Dashboard", url: "/" },
            { title: "Client", url: "/my-clients" },
          ]}
          currenPageTitle="List"
        />

        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5 style={{ fontWeight: 700 }}>All Users</h5>
                  {this.state.userRole != 10 && <div className="ibox-tools">
                    <a
                      href="/my-clients/add"
                      className="btn btn-primary btn-xs"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.navigate("/my-clients/add");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Create new User
                    </a>
                  </div>}
                </div>
                <div className="ibox-content">
                  <div className="table-responsive">
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper form-inline dt-bootstrap"
                    >
                      <div className="dt-buttons btn-group">
                        <a
                          className="dt-button buttons-csv buttons-html5"
                          tabIndex="0"
                          aria-controls="DataTables_Table_0"
                          href="#"
                          style={{ marginRight: "0.5rem" }}
                        >
                          <span>CSV</span>
                        </a>
                        <a
                          className="dt-button buttons-pdf buttons-html5"
                          tabIndex="0"
                          aria-controls="DataTables_Table_0"
                          href="#"
                        >
                          <span>PDF</span>
                        </a>
                      </div>

                      <div
                        id="DataTables_Table_0_filter"
                        className="dataTables_filter"
                      >
                        <label>
                          Search:
                          <input
                            type="search"
                            className="form-control input-sm"
                            placeholder=""
                            aria-controls="DataTables_Table_0"
                            value={this.state.keyword}
                            onChange={this.handleChange}
                          />
                        </label>
                      </div>

                      <table className="table table-striped table-bordered table-hover dataTables-example">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>User Name</th>
                            {/* <th>Name</th> */}
                            <th>Balance</th>
                            {/* {this.state.userRole != 8 &&
                              <th>Bonus Balance</th>
                            } */}
                            <th>Mobile</th>

                            {/* <th>Deposit</th>
                            <th>Withdraw</th> */}
                            <th>P/L</th>
                            <th>Exposure</th>

                            {/* <th>Match Commission</th>
                            <th>Session Commission</th> */}
                            {/* <th>Share</th>
                            <th>Casino Share</th> */}

                            <th>Withdraw</th>
                            <th>Deposit</th>


                            <th>User Lock</th>
                            <th>Bet Lock</th>
                            <th>Casino Lock</th>

                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {clientList && clientList.length ? (
                            clientList.map((user, index) => (
                              <tr className="gradeX" key={index}>
                                <td>
                                  {index + 1 + (currentPage - 1) * perPage}
                                </td>
                                <td>
                                  <a
                                    href="/"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.navigate(
                                        `/userview/client/${user.user_guid}/${user.username}`,
                                        {
                                          lastPath:
                                            this.props.location.pathname,
                                        }
                                      );
                                    }}
                                  >
                                    {user.username} {user.first_name && (`(${user.first_name})`)}
                                  </a>
                                </td>
                                {/* <td>
                                  {user.first_name} {user.last_name}
                                </td> */}
                                <td>
                                  {user.balance}
                                </td>
                                {/* {this.state.userRole != 8 &&
                                  <td>
                                    {user.bonus_balance}
                                  </td>
                                } */}
                                <td>
                                  <a href="tel:+91{{ user.mobile }}">
                                    +91{user.mobile}
                                  </a>
                                </td>

                                {/* <td>{user.total_deposit}</td>
                                <td>{user.total_withdraw}</td> */}
                                <td>{user.ledger_balance}</td>
                                <td>{user.exposure}</td>

                                {/* <td className="center">
                                  {user.match_commission}
                                </td>
                                <td className="center">
                                  {user.session_commission}
                                </td> */}
                                {/* <td className="center">{user.my_share}</td>
                                <td className="center">{user.game_share}</td> */}

                                <Fragment>
                                  <td>
                                    <button
                                      type="button"
                                      className="btn anvGreenBtn"
                                      onClick={() =>
                                        this.setToggleBalanceModal(user, "Withdraw")
                                      }
                                    >
                                      Withdraw
                                    </button>
                                  </td>
                                  <td>
                                    <button
                                      type="button"
                                      className="btn anvYellowBtn"
                                      onClick={() =>
                                        this.setToggleBalanceModal(user, "Deposit")
                                      }
                                    >
                                      Deposit
                                    </button>
                                  </td>
                                </Fragment>

                                <td className="center">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={user.status == "1"}
                                      onChange={(e) =>
                                        this.statusChange(e, user, false)
                                      }
                                    />
                                    <span className="slider round"></span>
                                  </label></td>
                                <td className="center">

                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        user.lock_betting == "0"
                                      }
                                      onChange={(e) =>
                                        this.lockUnlockBet(e, user, false)
                                      }
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </td>
                                <td className="center">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        user.lock_casino_bet == "0"
                                      }
                                      onChange={(e) =>
                                        this.lockUnlockCasino(e, user, false)
                                      }
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </td>

                                <td className="">
                                  <Link
                                    className="btn btn-white btn-sm"
                                    to={`/my-clients/edit/${user?.user_guid}`}
                                    state={{ selectedData: user }}
                                  >
                                    <i className="fa fa-pencil-square"></i> Edit
                                  </Link>
                                  &nbsp;&nbsp; &nbsp;&nbsp;


                                  <a
                                    className="btn btn-white btn-sm"
                                    href="/"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.navigate(
                                        `/edit_password/${user.user_guid}`
                                      );
                                    }}
                                  >
                                    <i className="fa fa-key"></i> CP
                                  </a>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="gradeX">
                              <td colSpan={11}>No data available in table</td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      <div className="row" style={{ margin: "15px 0px" }}>
                        <div className="col-xs-12 col-sm-6">
                          <div
                            className="dataTables_info"
                            id="DataTables_Table_0_info"
                            role="status"
                            aria-live="polite"
                          >
                            {totalRecords
                              ? this.getRecordDetails()
                              : "Showing 0 to 0 of 0 entries"}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                          <div className="paginate_class">
                            <ReactPaginate
                              previousLabel={"prev"}
                              nextLabel={"next"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={this.state.records_all}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={this.handlePageClick}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"}
                            />
                          </div>
                        </div>
                        <div className="clearfix visible-xs"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >
        </div >
        {UpdateBalanceModalShow && <UpdateBalanceModal {...UpdateBalanceModalProps} />}
      </>
    );
  }
}
export default List;
