import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CreateEmployee } from "../../_services/affiliate.services";
import { toast } from "react-toastify";
import InnerPageHeader from "components/common/InnerPageHeader";

export default function Add(props) {
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [myShare, setMyShare] = useState("");
  const [myAgentShare, setMyAgentShare] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      userName === "" ||
      firstName === "" ||
      lastName === "" ||
      myShare === "" ||
      myAgentShare === "" ||
      referralCode === "" ||
      password === ""
    ) {
      toast.error(
        <div>
          <i
            className="fa fa-times"
            style={{ fontWeight: "bold", marginRight: "1rem" }}
            aria-hidden="true"
          ></i>
          <span>All filds required</span>
        </div>,
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
      // toast.error("All filds required");
    } else {
      let sendObj = {};
      sendObj.username = userName;
      sendObj.password = password;
      sendObj.first_name = firstName;
      sendObj.last_name = lastName;
      sendObj.my_share = myShare;
      sendObj.my_agent_share = myAgentShare;
      sendObj.referral_code = referralCode;

      CreateEmployee(sendObj)
        .then(() => {
          navigate("/affiliate");
        })
        .catch(() => {
          toast.error(
            <div>
              <i
                className="fa fa-times"
                style={{ fontWeight: "bold", marginRight: "1rem" }}
                aria-hidden="true"
              ></i>
              <span>The username field must contain a unique value</span>
            </div>,
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
          // toast.error(" The username field must contain a unique value");
        });
    }
  };

  return (
    <>
      <InnerPageHeader
        pageTitle="Add Affiliate"
        breadcrumbs={[
          { title: "Dashboard", url: "/" },
          { title: "Affiliate List", url: "/affiliate" },
        ]}
        currenPageTitle="Add Affiliate"
      />
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-content">
                <div className="row">
                  <div className="col-lg-12">
                    <form autoComplete="off">
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          User Name
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            name="userName"
                            className="form-control"
                            placeholder="Enter User Name"
                            value={userName}
                            autoComplete="off"
                            onChange={(e) => setUserName(e.target.value)}
                          />
                        </div>
                      </div>
                      <hr />

                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          First Name
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            name="firstName"
                            className="form-control"
                            placeholder="Enter First Name"
                            value={firstName}
                            autoComplete="off"
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </div>
                      </div>
                      <hr />

                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          {" "}
                          Last Name{" "}
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            value={lastName}
                            autoComplete="off"
                            name="lastName"
                            placeholder="Enter Last Name"
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </div>
                      </div>
                      <hr />

                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          {" "}
                          Bonus %{" "}
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            value={myShare}
                            autoComplete="off"
                            name="lastName"
                            placeholder="Enter Bonus % "
                            onChange={(e) => setMyShare(e.target.value)}
                          />
                        </div>
                      </div>
                      <hr />

                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          {" "}
                          Client Bonus % {" "}
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            value={myAgentShare}
                            autoComplete="off"
                            name="lastName"
                            placeholder="Enter Client Bonus % "
                            onChange={(e) => setMyAgentShare(e.target.value)}
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          {" "}
                          Referral Code  {" "}
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            value={referralCode}
                            autoComplete="off"
                            name="lastName"
                            placeholder="Enter Referral Code  "
                            onChange={(e) => setReferralCode(e.target.value)}
                          />
                        </div>
                      </div>
                      <hr />

                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          Password
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="password"
                            className="form-control"
                            value={password}
                            autoComplete="off"
                            name="password"
                            placeholder=" Enter password "
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <hr />
                    </form>
                    <div className="form-group row">
                      <div className="col-sm-4 col-sm-offset-2">
                        <Link
                          className="btn btn-white mr-3"
                          type="button"
                          style={{ marginRight: "1rem" }}
                          to="/affiliate"
                        >
                          Cancel
                        </Link>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={(e) => onSubmit(e)}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
