import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import "./userview.css";
import { getEventMarket } from "_services";
import { Link } from "react-router-dom";
import InnerPageHeader from "components/common/InnerPageHeader";

function MatchesListIdComponent(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { matchData } = location.state;
  const { history } = props;
  const { id } = useParams();
  const userLogin = JSON.parse(localStorage.getItem("userLogin"));

  const [subCompanyLiveReportPage] = useState(userLogin);
  const [eventMarketData, updateEventMarketData] = useState(null);

  useEffect(() => {
    let sendData = {};
    sendData.event_id = id;
    getEventMarket(sendData)
      .then((response) => {
        if (response && response.data && response.data.data) {
          updateEventMarketData(response.data.data);
        } else {
        }
      })
      .catch(() => {});
  }, []);

  return (
    <div>
      <InnerPageHeader
        pageTitle="Match Dashboard"
        breadcrumbs={[
          { title: "Dashboard", url: "/" },
          {
            title: "Matches List",
            url: "/matches_list?sport_id=" + matchData?.sport_id,
          },
        ]}
        currenPageTitle={matchData?.event_name}
      />
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h1>Agent Match Dashboard</h1>
              </div>
              <div className="ibox-content table-responsive">
                <div style={{ textAlign: "center" }}>
                  {[
                    { title: "Bet Slips", url: "/bet_slips/" },
                    { title: "Session Bet Slip", url: "/session-bet-slips/" },
                    { title: "Live Report", url: "/live_report/" },
                    { title: "Company Report", url: "/company_report/" },
                    { title: "Client Report", url: "/client_report/" },
                    { title: "Collection Report", url: "/collection_report/" },
                  ].map((item) => (
                    <Link
                      key={item?.title}
                      to={item?.url + id}
                      state={{ matchData }}
                      className="mr-25"
                    >
                      <button type="button" className="btn btn-primary btn-lg">
                        {item?.title}
                      </button>
                    </Link>
                  ))}



                  {subCompanyLiveReportPage?.role == 1 ||
                  subCompanyLiveReportPage?.role == 9 ? (
                    <label className="dropdown">
                      <div className="dd-button btn btn-primary btn-lg">
                        Settings
                      </div>

                      <input type="checkbox" className="dd-input" id="test" />

                      <ul className="dd-menu" style={{ paddingBottom: 20 }}>
                        {[
                          { title: "Commentary", url: "/commentary/" },
                          { title: "News/Updates", url: "/newsupdates/" },
                          { title: "Stake Delay", url: "/stake_delay/" },
                          { title: "Score Widget", url: "/scorewidget/" },
                          { title: "Live TV", url: "/livetv/" },
                          { title: " Event type", url: "/eventtype/" },
                          { title: "Scoreboard", url: "/scoreboard/" },
                          { title: "Match ID", url: "/match_id/" },
                        ].map((item) => (
                          <li
                            key={item?.title}
                            onClick={() => {
                              navigate(item?.url + id, {
                                state: { matchData },
                              });
                            }}
                          >
                            {item?.title}
                          </li>
                        ))}
                      </ul>
                    </label>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>Match Markets</h5>
                <div className="ibox-tools"></div>
              </div>
              <div className="ibox-content table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Betfair Market Id</th>
                      <th>Betfair Market Type</th>
                      <th>RUNNER</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventMarketData &&
                      eventMarketData.market &&
                      eventMarketData.market.map((evetMarketItem, index) => {
                        return (
                          <tr key={evetMarketItem.market_id}>
                            <td>{evetMarketItem.market_id}</td>
                            <td>
                              <a
                                href={`/bet_slips/${id}`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  history.push({
                                    pathname: `/bet_slips/${id}`,
                                    state: { matchData },
                                  });
                                }}
                              >
                                {evetMarketItem.betfair_mkt_id}
                              </a>
                            </td>
                            <td>{evetMarketItem.market_name}</td>
                            <td>{evetMarketItem.runners}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>Match Sessions</h5>
                <div className="ibox-tools"></div>
              </div>
              <div className="ibox-content table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Session ID</th>
                      <th>Session Market</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventMarketData &&
                      eventMarketData.fancy &&
                      eventMarketData.fancy.map((evetMarketItem, index) => {
                        return (
                          <tr key={evetMarketItem.market_id + index}>
                            <td>{evetMarketItem.session_id}</td>
                            <td>
                              <Link
                                to={`/session-bet-slips/${id}`}
                                state={{ matchData }}
                              >
                                {evetMarketItem.runner_name}
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MatchesListIdComponent;
