import React from "react";
import BlockedComponent from "components/Client/Blocked";
import { useLocation } from "react-router-dom";

function BlockedAffiliate() {
  const location = useLocation();
  return (
    <BlockedComponent
      location={location}
      title="Blocked Affiliate Agents List"
      role={[8]}
    />
  );
}

export default BlockedAffiliate;
