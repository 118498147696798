import React from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import axios from "axios";

import { routers } from "./App";
import { store } from "./_helpers";

axios.interceptors.response.use(undefined, (error) => {
  if (error && error.response && error.response.status === 401) {
    localStorage.clear();
    if (error?.config?.url.search('/login') <= 0) {
      window.location.reload();
    }
  }
  return Promise.reject(error);
});

const root = createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <>
    <Provider store={store}>
      <RouterProvider router={routers} />
      <ToastContainer />
    </Provider>
  </>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
