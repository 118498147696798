export const userProfileConstants = {
  USERPROFILE_REQUEST: "USERPROFILE_REQUEST",
  USERPROFILE_SUCCESS: "USERPROFILE_SUCCESS",
  USERPROFILE_FAILED: "USERPROFILE_FAILED",

  AFFILIATE_DASHBOARD_REQUEST: "AFFILIATE_DASHBOARD_REQUEST",
  AFFILIATE_DASHBOARD_SUCCESS: "AFFILIATE_DASHBOARD_SUCCESS",
  AFFILIATE_DASHBOARD_FAILED: "AFFILIATE_DASHBOARD_FAILED",

  CLIENT_DASHBOARD_REQUEST: "CLIENT_DASHBOARD_REQUEST",
  CLIENT_DASHBOARD_SUCCESS: "CLIENT_DASHBOARD_SUCCESS",
  CLIENT_DASHBOARD_FAILED: "CLIENT_DASHBOARD_FAILED",

  DP_WD_DASHBOARD_REQUEST: "DP_WD_DASHBOARD_REQUEST",
  DP_WD_DASHBOARD_SUCCESS: "DP_WD_DASHBOARD_SUCCESS",
  DP_WD_DASHBOARD_FAILED: "DP_WD_DASHBOARD_FAILED",

};
