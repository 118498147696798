import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import {
  createAnnouncement,
  companyAnnouncement,
  userAnnouncement,
  userAnnouncementList,
  deleteUserAnnouncement,
  matkaAnnouncement,
  getMatkaAnnouncement,
} from "_services/announcement.services";
import { BannerConsumer } from "components/layouts/Main";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import ReactSelect from "react-select";
import { userListByKeyword } from "_services";
import { toast } from "react-toastify";
import ConfrimModal from "components/ConfirmModal";
import InnerPageHeader from "components/common/InnerPageHeader";
export class Announcement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userAnnouncement: "",
      clientAnnouncement: "",
      announcementText: "",
      announcement: "",
      currentRole: "",
      keyword: "",
      typing: false,
      typingTimeout: 0,
      userList: [],
      announcementList: [],
      deleteConfirm: false,
      loadingDeleteConfirm: false,
    };
  }

  handleInput = (e) => {
    let label = e.target.name;
    let value = e.target.value;
    if (label === "userAnnouncement")
      this.setState({ userAnnouncement: value });
    else this.setState({ [label]: value });
  };

  onSubmit(e, callBack) {
    let sendData = {};

    sendData.type = 0;
    sendData.selected_userids = [];
    sendData.client_msg = this.state.clientAnnouncement;
    sendData.admin_type = 0;
    sendData.selected_admin_userids = [];
    sendData.admin_agent_msg = this.state.userAnnouncement;
    createAnnouncement(sendData)
      .then((response) => {
        callBack.setToggleBaner();

        // this.setState({
        //   userAnnouncement: "",
        //   clientAnnouncement: "",
        //   currentRole: "",
        // });
      })
      .catch((error) => {});
  }

  onMatkaSubmit() {
    const sendData = {
      announcement: this.state.announcement,
    };

    matkaAnnouncement(sendData)
      .then(async (response) => {
        // this.setState({
        //   userAnnouncement: "",
        //   clientAnnouncement: "",
        //   currentRole: "",
        // });
      })
      .catch((error) => {});
  }

  componentDidMount() {
    this.setState({ currentRole: localStorage.getItem("role") });

    this.functionAnnoucement();
    this.getUserAnnouncementList();
    this.getMatkaAnnounce();
  }

  async getMatkaAnnounce() {
    const res = await getMatkaAnnouncement();
    if (res?.data?.data?.announcement) {
      this.setState({ announcement: res?.data?.data?.announcement });
    }
  }

  functionAnnoucement() {
    companyAnnouncement()
      .then((res) => {
        this.setState({
          userAnnouncement: res.data.data.admin_agent_msg,
          clientAnnouncement: res.data.data.client_msg,
          currentRole: "",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  changeUserName = (value) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      keyword: value,
      typing: false,
      typingTimeout: setTimeout(async () => {
        if (value?.length >= 2) {
          const res = await userListByKeyword({ keyword: value });
          if (res && res?.data?.data) {
            const userList = res?.data?.data?.users.map((item) => ({
              ...item,
              label: item?.username + "," + item?.name,
              value: item?.user_id,
              data: item,
            }));
            this.setState({ userList: userList, update: !this.state.update });
          }
        } else {
          this.setState({ userList: [] });
        }
      }, 1000),
    });
  };

  onSubmitUser() {
    if (this.state?.selectedUser?.length > 0 && this.state?.announcementText) {
      let sendData = {
        user_detail: this.state.selectedUser.map((item) => ({
          user_guid: item?.user_guid,
          user_name: item?.name,
          user_id: item?.user_id,
        })),
        announcement: this.state.announcementText,
      };
      userAnnouncement(sendData)
        .then((response) => {
          if (response?.status) {
            this.setState({ selectedUser: [], announcementText: "" });
            this.getUserAnnouncementList();
          }
        })
        .catch((error) => {});
    } else {
      toast.error(
        this.state?.announcementText === ""
          ? "announcement text is required"
          : "please select user"
      );
    }
  }

  async deleteUserAnnouncementList() {
    this.setState({ loadingDeleteConfirm: true });
    const res = await deleteUserAnnouncement({
      id: this.state?.deleteConfirm?.$oid,
    });
    if (res) {
      this.setState({ deleteConfirm: false, loadingDeleteConfirm: false });
      this.getUserAnnouncementList();
    }
  }

  async getUserAnnouncementList() {
    const res = await userAnnouncementList();
    if (res) {
      this.setState({
        announcementList: res?.data?.data ? res?.data?.data : [],
      });
    }
  }

  render() {
    const {
      userAnnouncement,
      clientAnnouncement,
      announcement,
      currentRole,
      userList,
      announcementText,
      announcementList,
      deleteConfirm,
      loadingDeleteConfirm,
    } = this.state;
    const formatOptionLabel = ({ data }) => {
      return (
        <div label={data?.name} data={data} innerProps={{}}>
          {data?.username + ` ( ${data?.name} ) `}
        </div>
      );
    };

    return (
      <>
        <BannerConsumer>
          {(value) => {
            return (
              <Fragment>
                <InnerPageHeader
                  pageTitle="Announcement"
                  breadcrumbs={[{ title: "Dashboard", url: "/" }]}
                  currenPageTitle="Announcement"
                />
                <div
                  className="wrapper wrapper-content animated fadeInRight"
                  style={{ paddingBottom: 0 }}
                >
                  <div className="row">
                    <div className="col-12">
                      <div className="ibox">
                        <div className="ibox-title">
                          <h5>Announcement</h5>
                        </div>
                        <div className="ibox-content">
                          <div className="row">
                            <div className="col-lg-12">
                              <form>
                                {Number(currentRole) <= 6 && (
                                  <Fragment>
                                    <div className="form-group row">
                                      <label
                                        htmlFor="inputUserAnnouncement"
                                        className="col-sm-2 col-form-label"
                                      >
                                        Downline Announcement
                                      </label>
                                      <div className="col-sm-10">
                                        <textarea
                                          style={{ maxWidth: "95%" }}
                                          type="textarea"
                                          className="form-control"
                                          id="inputUserAnnouncement"
                                          value={userAnnouncement}
                                          name="userAnnouncement"
                                          placeholder="Downline Announcement"
                                          onChange={(e) => this.handleInput(e)}
                                        />
                                      </div>
                                    </div>
                                    <hr />
                                  </Fragment>
                                )}
                                <div className="form-group row">
                                  <label
                                    htmlFor="inputAgentAnnouncement"
                                    className="col-sm-2 col-form-label"
                                  >
                                    Clients Announcement
                                  </label>
                                  <div className="col-sm-10">
                                    <textarea
                                      style={{ maxWidth: "95%" }}
                                      type="textarea"
                                      name="clientAnnouncement"
                                      className="form-control"
                                      id="inputAgentAnnouncement"
                                      placeholder="Clients Announcement"
                                      value={clientAnnouncement}
                                      onChange={(e) => this.handleInput(e)}
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="form-group row">
                                <div className="col-sm-4 col-sm-offset-2">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={(e) => this.onSubmit(e, value)}
                                  >
                                    Save Changes
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
             
           
              </Fragment>
            );
          }}
        </BannerConsumer>
        {deleteConfirm && (
          <ConfrimModal
            isShow={Boolean(deleteConfirm)}
            message={"Are you sure you want to Detete"}
            onCancle={() => this.setState({ deleteConfirm: "" })}
            onSubmit={() => this.deleteUserAnnouncementList()}
            loading={loadingDeleteConfirm}
          />
        )}
      </>
    );
  }
}

export default Announcement;
