import React from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AddUser from "components/common/AddUser";
import { createRequest } from "_actions/master/master.actions";

function Add(props) {
  const role = 6;
  const navigationUrl = "/super";
  const title = "Super";

  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const createUser = (sendObj) => {
    dispatch(
      createRequest(sendObj, (res) => {
        if (res) {
          navigate(navigationUrl);
        }
      })
    );
  };

  return (
    <AddUser
      navigationUrl={navigationUrl}
      onSubmitFunc={createUser}
      role={role}
      action={params?.id ? "Edit " + title : "Create " + title}
      navigate={navigate}
      parentType={title}
      isEdit={Boolean(params?.id)}
      params={params}
      showFixSharing={true}
      {...props}
    />
  );
}
const mapstatetop = (state) => ({
  ...state,
});

export const CreateSuper = connect(mapstatetop)(Add);
