import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

import ReactPaginate from "react-paginate";
import InnerPageHeader from "components/common/InnerPageHeader";
import { depositRequest } from "_services";
import { formatDateAndTime } from "utils";
import { DepositRequestModal } from "modals";
import TagAndChips from "components/common/TagAndChips";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useScreenDetector } from "hooks/useScreenDetector";
import { FaWindowClose } from "react-icons/fa";

const DepositRequest = () => {
    const [role] = useState(localStorage.getItem("role") || null);
    const { isMobile } = useScreenDetector();
    const [depositeList, setDepositList] = useState([]);
    const [status, setStatus] = useState(0);
    const [showModal, setModal] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [pageChange, setPageChange] = useState(1);
    const [perPage] = useState(100);
    const [pageCount, setPageCount] = useState(0);
    const [from_date, setFromDate] = useState(null);
    const [to_date, setToDate] = useState(null);
    const [keyword, setKeyword] = useState('')
    const [showDepositImage, setShowDepositImage] = useState(true)
    const [selectedImage, setSelectedImage] = useState(false)

    useEffect(() => {
        getDepositList(status);
    }, [(from_date && to_date) || pageChange && keyword]);



    const getDepositList = (type) => {
        setStatus(type)
        let param = {
            status: type,
            current_page: pageChange,
            items_perpage: perPage,
            keyword
        }
        if (from_date && to_date) {
            param["from_date"] = moment(
                moment.utc(from_date).local().format("YYYY-MM-DD 00:00:00"),
                "YYYY-MM-DD HH:mm:ss"
            )
                .utc()
                .format("YYYY-MM-DD HH:mm:ss");
            param["to_date"] = moment(
                moment.utc(to_date).local().format("YYYY-MM-DD 23:59:00"),
                "YYYY-MM-DD HH:mm:ss"
            )
                .utc()
                .format("YYYY-MM-DD HH:mm:ss");
        }

        depositRequest(param)
            .then((response) => {
                if (response?.data?.data) {
                    setDepositList(response?.data?.data.records);
                    setPageCount(
                        Math.ceil(response?.data?.data?.total_records / perPage)
                    );
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const depositAction = (data) => {
        setSelectedData(data)
        toggleModal()

    }
    const toggleModal = () => {
        setModal(!showModal)
    }
    const DepositRequestModalProps = {
        isShow: showModal,
        data: selectedData,
        modalToggle: toggleModal,
        getDepositList: () => getDepositList(status)
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setPageChange(selectedPage + 1);
    };

    const handleCallback = (from_date, to_date) => {

        console.log(from_date, to_date)
        setFromDate(from_date);
        setToDate(to_date)
        setPageChange(1);

        // getDepositList(status)
    };

    const showImage = (image) => {
        setSelectedImage(image)
        setShowDepositImage(true)
    }

    return (
        <>
            <InnerPageHeader
                pageTitle="DEPOSIT REQUESTS"
                breadcrumbs={[{ title: "Dashboard", url: "/" }]}
                currenPageTitle="DEPOSIT REQUESTS"
            />
            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-12">
                        <div className="ibox">
                            <div className="ibox-title">
                                <h5>Deposit Request</h5>
                            </div>
                            <div className="ibox-title">
                                <div className={isMobile ? "" : "d-flex"}>
                                    <div className="d-flex justify-start gap-2 overflow-x-scroll" style={{ alignItems: 'center' }}>
                                        <TagAndChips className={status == 0 ? "btn btn-primary2" : "btn btn-primary4"} onClick={() => getDepositList(0)}>
                                            Pending
                                        </TagAndChips>
                                        <TagAndChips className={status == 1 ? "btn btn-primary2" : "btn btn-primary4"} onClick={() => getDepositList(1)}>
                                            Completed
                                        </TagAndChips>
                                        <TagAndChips className={status == 2 ? "btn btn-primary2" : "btn btn-primary4"} onClick={() => getDepositList(2)}>
                                            Cancelled
                                        </TagAndChips>
                                        <div>
                                            <input
                                                type="text"
                                                value={keyword}
                                                placeholder="search by UTR No"
                                                onChange={(e) => { setKeyword(e.target.value) }} />
                                        </div>


                                    </div>
                                    <DateRangePicker
                                        initialSettings={{
                                            startDate: from_date
                                                ? from_date.toDate()
                                                : moment().subtract(6, "days"),
                                            endDate: to_date ? to_date.toDate() : moment(),
                                            ranges: {
                                                Today: [moment().toDate(), moment().toDate()],
                                                Yesterday: [
                                                    moment().subtract(1, "days").toDate(),
                                                    moment().subtract(1, "days").toDate(),
                                                ],
                                                "Last 7 Days": [
                                                    moment().subtract(6, "days").toDate(),
                                                    moment().toDate(),
                                                ],
                                                "This Week": [
                                                    moment().startOf("isoWeek").toDate(),
                                                    moment().toDate(),
                                                ],
                                                "Last Week": [
                                                    moment().subtract(1, "week").startOf("isoWeek").toDate(),
                                                    moment().subtract(1, "week").endOf("isoWeek").toDate(),
                                                ],
                                                "Last 30 Days": [
                                                    moment().subtract(29, "days").toDate(),
                                                    moment().toDate(),
                                                ],
                                                "This Month": [
                                                    moment().startOf("month").toDate(),
                                                    moment().toDate(),
                                                ],
                                                "Last Month": [
                                                    moment().subtract(1, "month").startOf("month").toDate(),
                                                    moment().subtract(1, "month").endOf("month").toDate(),
                                                ],
                                            },
                                        }}
                                        onCallback={handleCallback}
                                    >
                                        <div
                                            id="reportrange"
                                            style={{
                                                padding: 10,
                                                background: "none repeat scroll 0% 0% #fff",
                                                cursor: "pointer",
                                                padding: "5px 10px",
                                                border: "1px solid #ccc",
                                                margin: " 10px 0px 0px 25px",
                                                maxWidth: isMobile ? "100%" : "25%",
                                            }}
                                            className="col-lg-3 col-md-3 col-sm-9 mlDatePicke"
                                        >
                                            <span
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    padding: 0,
                                                }}
                                            >
                                                <i
                                                    className={"glyphicon glyphicon-calendar  fa fa-calendar"}
                                                ></i>
                                                &nbsp;&nbsp;
                                                <span className="text-right">
                                                    {from_date && to_date
                                                        ? `${from_date.format("DD MMM, YYYY")} - ${to_date.format(
                                                            "DD MMM, YYYY"
                                                        )}`
                                                        : "-"}
                                                </span>
                                                &nbsp;&nbsp;
                                                <i className="fa fa-caret-down" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                    </DateRangePicker>
                                </div>
                            </div>
                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-responsive  table-striped dataTables-example">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Username</th>
                                                <th>Mobile</th>
                                                <th>Amount</th>
                                                <th>Payment Mode</th>
                                                <th>UTR No</th>
                                                <th>Image</th>
                                                <th>Status</th>
                                                <th>Date</th>
                                                {role != 8 && <th className="text-center" >
                                                    {status == 0 && "Action"}
                                                </th>}
                                            </tr>
                                        </thead>
                                        {depositeList && depositeList.length > 0 ? <tbody>
                                            {depositeList?.map((element, ind) => {
                                                return (
                                                    <tr key={ind}>
                                                        <td>{ind + 1}</td>
                                                        <td>
                                                            <Link to={`/userview/client/${element.user_guid}/${element.username}`}
                                                            >
                                                                {element.username}
                                                            </Link>
                                                        </td>

                                                        <td>{element.mobile}</td>
                                                        <td>{element.amount}</td>
                                                        <td>{element.payment_mode}</td>
                                                        <td>{element.utr_no}</td>
                                                        <td>
                                                            <div onClick={() => { showImage(element.image_path) }}>
                                                                {/* <a href={element.image_path} target="_blank" rel="noopener noreferrer"> */}
                                                                <img src={element.image_path} alt="" height={50} width={100} />
                                                                {/* </a> */}
                                                            </div>
                                                        </td>
                                                        <td>{element.status == 0 ? 'Pending' : element.status == 1 ? "Completed" : element.status == 2 ? "Cancelled" : ""}</td>
                                                        <td> {element.created_at ? formatDateAndTime("MMM DD, hh:mm:ss A", element.created_at) : "-"} { }</td>
                                                        {role != 8 && <td>
                                                            {
                                                                status == 0 && element.status == 0 && <a className="btn btn-white btn-sm" onClick={() =>
                                                                    depositAction(element, "")
                                                                }>Action </a>
                                                            }
                                                        </td>}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                            :
                                            <tfoot >
                                                <tr><th className="text-center" colSpan={9}> No record found</th></tr>
                                            </tfoot>
                                        }
                                    </table>

                                    <div className="paginate_class">
                                        <ReactPaginate
                                            previousLabel={"prev"}
                                            nextLabel={"next"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {showModal && <DepositRequestModal {...DepositRequestModalProps} />
            }
            {showDepositImage && selectedImage &&
                <div
                    style={{
                        position: 'fixed',
                        zIndex: 100000, width: '100%',
                        height: '100vh', top: 0, left: 0,
                        background: 'rgba(0,0,0,0.6)', display: 'flex',
                        alignItems: 'center', justifyContent: 'center'
                    }}
                    onClick={() => { setShowDepositImage(false); setSelectedImage(null) }}
                >
                    <div style={{ width: 'auto', height: '95vh', background: '#ffffff' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px', width: '100%', height: '5vh' }}>
                            <p>View Payment Image</p>
                            <FaWindowClose size={20} color="red" onClick={() => { setShowDepositImage(false); setSelectedImage(null) }} />
                        </div>
                        <div style={{ width: '100%', height: '90vh', }}>
                            <img src={selectedImage} alt="" style={{ height: '100%' }} />
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default DepositRequest;
