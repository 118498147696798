import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

const PageTabs = ({ event_id, state, path }) => {
    const location = useLocation();
    const { matchData } = location?.state;

    return (
        <div className="switch-tabs">
            <ul>
                {[
                    { title: "Live Report", url: "/live_report/" },
                    { title: "Bet Slips", url: "/bet_slips/" },
                    { title: "Session Bet Slip", url: "/session-bet-slips/" },
                    { title: "Company Report", url: "/company_report/" },
                    { title: "Client Report", url: "/client_report/" },
                    { title: "Collection Report", url: "/collection_report/" },
                    { title: "News", url: "/newsupdates/" },
                    { title: "Live TV", url: "/livetv/" },
                    { title: "Score Widget", url: "/scorewidget/" },
                    { title: "Stake Delay", url: "/stake_delay/" },
                ].map((item) => (
                    <li className={path && path == item.url ? 'activeSwitchTab' : ''}>
                        <Link
                            key={item?.title}
                            to={item?.url + event_id}
                            state={{ matchData: state ? state : matchData }}
                            className=""
                        >
                            <p >
                                {item?.title}
                            </p>
                        </Link>
                    </li>
                ))}
                {/* <li>
                    {subCompanyLiveReportPage?.role == 1 ||
                        subCompanyLiveReportPage?.role == 9 ? (
                        <label className="dropdown">
                            <div className="dd-button bt btn-primar btn-l">
                                Settings
                            </div>

                            <input type="checkbox" className="dd-input" id="test" />

                            <ul className="dd-menu" style={{ paddingBottom: 20 }}>
                                {[
                                    { title: "Commentary", url: "/commentary/" },
                                    { title: "News/Updates", url: "/newsupdates/" },
                                    { title: "Stake Delay", url: "/stake_delay/" },
                                    { title: "Score Widget", url: "/scorewidget/" },
                                    { title: "Live TV", url: "/livetv/" },
                                    { title: " Event type", url: "/eventtype/" },
                                    { title: "Scoreboard", url: "/scoreboard/" },
                                    { title: "Match ID", url: "/match_id/" },
                                ].map((item) => (
                                    <li
                                        key={item?.title}
                                        onClick={() => {
                                            navigate(item?.url + event_id, {
                                                state: { state },
                                            });
                                        }}
                                    >
                                        {item?.title}
                                    </li>
                                ))}
                            </ul>
                        </label>
                    ) : null}
                </li> */}
            </ul>
        </div>
    )
}

export default PageTabs